/**
 * Ensure that a value is a collection.
 *
 * @param collection A value that might be a collection.
 */
export function getCollection(collection) {
    return Array.isArray(collection) ? collection : [];
}
/**
 * Ensure that a value is a collection that has items in it.
 *
 * @param collection A value that might be a collection with items in it.
 */
export function hasItems(collection) {
    return Array.isArray(collection) && collection.length > 0;
}
/**
 * Group a collection of objects by the value of one of their fields.
 *
 * @param field The field to get a value from in each object.
 * @param filter A an optional group of values that are allowed.
 */
export function groupByFieldValue(field, filter = []) {
    const hasFilter = hasItems(filter);
    return (collection) => {
        const groups = [];
        const groupMap = {};
        for (const item of getCollection(collection)) {
            const groupKey = item[field];
            if (hasFilter && !filter.includes(groupKey)) {
                continue;
            }
            const group = groupMap[groupKey];
            if (!hasItems(group)) {
                const newGroup = [item]; // TODO: fix types...
                groupMap[groupKey] = newGroup;
                groups.push(newGroup);
            }
            else {
                group.push(item);
            }
        }
        return groups;
    };
}
