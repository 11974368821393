var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
export const AuthContext = React.createContext({
    loading: true,
    user: null,
    isAdmin: false,
});
const AuthProvider = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [user, setUser] = useState(null);
    useEffect(() => {
        function loadUserData() {
            return __awaiter(this, void 0, void 0, function* () {
                const [user, session] = yield Promise.all([
                    Auth.currentAuthenticatedUser(),
                    Auth.currentSession(),
                ]);
                setUser({ username: user.username, email: user.attributes.email });
                const userClaims = session.getIdToken().payload;
                const userGroups = userClaims['cognito:groups'] || [];
                const inAdminGroup = userGroups
                    .map((g) => g.toLowerCase())
                    .includes('admin');
                setIsAdmin(inAdminGroup);
            });
        }
        loadUserData();
    }, []);
    return (_jsx(AuthContext.Provider, Object.assign({ value: {
            loading: !user,
            user,
            isAdmin,
        } }, { children: children })));
};
export default AuthProvider;
