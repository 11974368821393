import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from 'semantic-ui-react';
class PageErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false,
        };
    }
    static getDerivedStateFromError(error) {
        console.error(error);
        return { hasError: true };
    }
    render() {
        if (this.state.hasError) {
            return this.renderError();
        }
        return this.props.children;
    }
    renderError() {
        const handleClick = () => window.location.reload();
        return (_jsxs("div", { children: ["Error loading page.", ' ', _jsx(Button, Object.assign({ onClick: handleClick }, { children: "Reload" }))] }));
    }
}
export default PageErrorBoundary;
