export function vagrantfileSample(boxName, options = {}) {
    return `# -*- mode: ruby -*-
# vi: set ft=ruby :

Vagrant.configure("2") do |config|
  config.vm.box = "${boxName}"${options.boxVersion
        ? `\n  config.vm.box_version = "${options.boxVersion}"`
        : ''}
end`;
}
export function containerTemplateSample(boxName, options = {}) {
    return `# adjust network and connection configurations as needed

version: "1"
  parameters: {}
  boxes:
    box_01:
      name: box_01
      box_location: ${boxName}${options.boxVersion
        ? `\n      box_version: "${options.boxVersion}"`
        : ''}
      networks:
        - type: private
          ip: "192.168.10.10"
      connections:
        - port: 3389
          local_port: 23300
          protocol: rdp`;
}
