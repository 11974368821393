import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Pagination, Radio, Table, } from 'semantic-ui-react';
import { onClick, sortByBooleanField, sortByLinkField, sortByNumberField, sortByStringField, } from '../../utils';
import classes from './table.module.css';
export function DataTable({ data, columns, color, rowsPerPage = 5, onSelected, }) {
    const [activePage, setActivePage] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [sortColumn, setSortColumn] = useState(Array.isArray(columns) && columns.length > 0
        ? columns[columns.length - 1]
        : null);
    const [sortDirection, setSortDirection] = useState('descending');
    function handlePageChange(_, { activePage }) {
        setActivePage(!Number.isNaN(activePage) ? Number(activePage) : 1);
    }
    function getSortDirection(fieldName) {
        return (sortColumn === null || sortColumn === void 0 ? void 0 : sortColumn.name) === fieldName ? sortDirection : undefined;
    }
    function toggleSort(fieldName) {
        return function updateSort() {
            if ((sortColumn === null || sortColumn === void 0 ? void 0 : sortColumn.name) !== fieldName) {
                const newSortField = columns.find((column) => column.name === fieldName) || null;
                setSortColumn(newSortField);
                return setSortDirection('descending');
            }
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        };
    }
    function handleSelected(index) {
        return (event) => {
            event.stopPropagation();
            event.preventDefault();
            const item = data[index];
            if (!item || item === selectedItem) {
                setSelectedItem(null);
                setSelectedIndex(-1);
                if (onSelected) {
                    onSelected(null);
                }
            }
            else {
                setSelectedItem(item);
                setSelectedIndex(index);
                if (onSelected) {
                    onSelected(item);
                }
            }
        };
    }
    const sorter = (sortColumn === null || sortColumn === void 0 ? void 0 : sortColumn.valueType) === 'string'
        ? sortByStringField
        : (sortColumn === null || sortColumn === void 0 ? void 0 : sortColumn.valueType) === 'boolean'
            ? sortByBooleanField
            : (sortColumn === null || sortColumn === void 0 ? void 0 : sortColumn.valueType) === 'number'
                ? sortByNumberField
                : sortByLinkField;
    const startIndex = (activePage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const sortedRecords = sortColumn !== null
        ? data.sort(sorter((sortColumn === null || sortColumn === void 0 ? void 0 : sortColumn.name) || '', sortDirection))
        : data;
    const visibleRecords = sortedRecords.slice(startIndex, endIndex);
    const totalPages = Math.ceil(data.length / rowsPerPage);
    return (_jsxs("div", Object.assign({ className: classes.tableContainer }, { children: [_jsxs(Table, Object.assign({ selectable: true, color: color, className: classes.table }, { children: [_jsx(Table.Header, { children: _jsxs(Table.Row, { children: [_jsx(Table.HeaderCell, { collapsing: true }, "first"), columns.map(({ label, name }, i) => (_jsx(Table.HeaderCell, Object.assign({ className: classes.tableHeader, sorted: getSortDirection(name), onClick: onClick(toggleSort(name)) }, { children: label }), i)))] }) }), _jsxs(Table.Body, { children: [visibleRecords.map((item, i) => (_jsxs(Table.Row, Object.assign({ onClick: handleSelected(i) }, { children: [_jsx(Table.Cell, Object.assign({ collapsing: true }, { children: _jsx(Radio, { checked: i === selectedIndex, onClick: handleSelected(i) }) }), `row-first-${i}`), columns.map(({ name, valueType, format }, j) => valueType === 'string' ? (_jsx(Table.Cell, { children: format(item[name]) }, `row-${i}-${j}`)) : valueType === 'boolean' ? (_jsx(Table.Cell, { children: item[name] ? (_jsx(Icon, { name: "check", color: "green" })) : (_jsx(Icon, { name: "close", color: "red" })) }, `row-${i}-${j}`)) : valueType === 'number' ? (_jsx(Table.Cell, { children: format(item[name]) }, `row-${i}-${j}`)) : (_jsx(Table.Cell, { children: _jsx(Link, Object.assign({ to: item[name]
                                                .uri }, { children: item[name]
                                                .label })) }, `row-${i}-${j}`)))] }), `row-${i}`))), data.length === 0 && (_jsx(Table.Row, { children: _jsx(Table.Cell, Object.assign({ colSpan: columns.length + 1, textAlign: "center" }, { children: "No data" })) }))] })] })), _jsx(Pagination, { activePage: activePage, totalPages: totalPages, onPageChange: handlePageChange })] })));
}
