import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Auth } from 'aws-amplify';
import { NavLink } from 'react-router-dom';
import { Container, Dropdown, Icon, Menu } from 'semantic-ui-react';
import { useAuth } from '../hooks';
import logo from '../images/logo-tsp.svg';
import classes from './default_layout.module.css';
export default function DefaultLayout({ children }) {
    const { user } = useAuth();
    return (_jsxs("div", Object.assign({ id: "default_layout" }, { children: [_jsx(Menu, Object.assign({ as: "menu", inverted: true, secondary: true, pointing: true, style: { background: '#1F1646', borderRadius: 0 } }, { children: _jsxs(Container, Object.assign({ fluid: true, className: classes.navbar }, { children: [_jsx(Menu.Item, Object.assign({ as: NavLink, to: "/dashboard" }, { children: _jsx("img", { src: logo, style: { width: 80, height: 30 }, alt: "CastleClone logo" }) })), _jsx(Menu.Item, Object.assign({ as: NavLink, to: "/templates" }, { children: "Templates" })), _jsx(Menu.Item, Object.assign({ as: NavLink, to: "/boxes" }, { children: "Boxes" })), _jsx(Menu.Item, Object.assign({ as: NavLink, to: "/data" }, { children: "Storage" })), _jsxs(Menu.Menu, Object.assign({ position: "right", "data-testid": "user-menu" }, { children: [_jsxs(Menu.Item, Object.assign({ href: "https://lms.dev.ccpams.com", target: "_blank" }, { children: [_jsx("span", { children: "Learn" }), _jsx(Icon, { name: "graduation", size: "large", style: { marginLeft: '0.25em' } })] })), _jsx(Dropdown, Object.assign({ item: true, simple: true, direction: "right", text: user === null || user === void 0 ? void 0 : user.username }, { children: _jsxs(Dropdown.Menu, { children: [_jsx(Dropdown.Item, Object.assign({ as: NavLink, to: "/settings" }, { children: "Settings" })), _jsx(Dropdown.Item, Object.assign({ onClick: () => {
                                                    Auth.signOut().then(() => window.location.reload());
                                                }, "data-testid": "auth-logout" }, { children: "Logout" }))] }) }))] }))] })) })), _jsx(Container, Object.assign({ as: "main", fluid: true, className: classes.content }, { children: children }))] })));
}
