var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-semantic-toasts';
import { Button, Checkbox, Form, Modal } from 'semantic-ui-react';
import { api } from '../../api';
import { UserRole } from '../../api/gen';
import { BaseTextInput } from '../../components/form/text_input';
export const CreateUserModal = ({ open, onClose, }) => {
    const { register, handleSubmit, formState: { errors }, reset, } = useForm();
    const queryClient = useQueryClient();
    const [isAdmin, setIsAdmin] = useState(false);
    const testOnSubmit = (data) => {
        alert(JSON.stringify(data));
    };
    const mutCreate = useMutation((data) => api.users.createUser(data), {
        onSuccess: (response) => __awaiter(void 0, void 0, void 0, function* () {
            yield queryClient.refetchQueries(['data-users']);
            toast({
                title: 'User Created',
                type: 'success',
                description: (_jsxs("p", { children: ["User ", response.username, " created"] })),
            });
            onClose();
        }),
        onError: (error) => {
            var _a;
            toast({
                type: 'error',
                title: 'Failed to create user',
                description: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.detail,
            });
        },
    });
    return (_jsxs(Modal, Object.assign({ open: open, onClose: onClose }, { children: [_jsx(Modal.Header, { children: "Create new user" }), _jsx(Modal.Content, { children: _jsxs(Modal.Description, { children: [_jsx("p", { children: "An invite will be sent to the user via email with additional instructions." }), _jsxs(Form, Object.assign({ onSubmit: handleSubmit(({ first_name, last_name, email }) => {
                                const userAttributes = [
                                    {
                                        name: 'family_name',
                                        value: last_name,
                                    },
                                    {
                                        name: 'given_name',
                                        value: first_name,
                                    },
                                    {
                                        name: 'email_verified',
                                        value: 'true',
                                    },
                                ];
                                const createUserRequest = {
                                    username: email,
                                    attributes: userAttributes,
                                    email: email,
                                    role: isAdmin
                                        ? UserRole.Admin
                                        : UserRole.Student,
                                };
                                mutCreate.mutateAsync(createUserRequest);
                            }) }, { children: [_jsx(BaseTextInput, Object.assign({}, register('first_name'), { label: "First Name", name: "first_name", errors: errors })), _jsx(BaseTextInput, Object.assign({}, register('last_name'), { label: "Last Name", name: "last_name", errors: errors })), _jsxs(Form.Group, Object.assign({ widths: "equal" }, { children: [_jsx(BaseTextInput, Object.assign({}, register('email'), { label: "Email", name: "email", errors: errors })), _jsxs(Form.Field, { children: [_jsx("label", { children: "Admin" }), _jsx(Checkbox, { toggle: true, checked: isAdmin, onChange: () => setIsAdmin(!isAdmin) })] })] })), _jsx(Form.Field, { control: Button, content: "Submit" })] }))] }) })] })));
};
