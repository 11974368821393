var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Auth } from '@aws-amplify/auth';
import AWS from 'aws-sdk';
import axios from 'axios';
import { BoxVisibility, } from '../types';
export function BoxService() {
    const routePrefix = 'service/v1/boxes';
    const baseUrl = process.env.REACT_APP_BOX_SERVICE_URL || '';
    const userPoolRegion = process.env.REACT_APP_USER_POOL_REGION || '';
    const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID || '';
    const userPoolId = process.env.REACT_APP_USER_POOL_ID || '';
    function getItem(path, key) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const url = `${baseUrl}/${routePrefix}/${path}`;
                const session = yield Auth.currentSession();
                const accessToken = session.getAccessToken().getJwtToken();
                const response = yield axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const data = response.data[key];
                return data;
            }
            catch (err) {
                console.error(err);
                return null;
            }
        });
    }
    function getList(path, key) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const url = `${baseUrl}/${routePrefix}/${path}`;
                const session = yield Auth.currentSession();
                const accessToken = session.getAccessToken().getJwtToken();
                const response = yield axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const data = response.data[key];
                return Array.isArray(data) ? data : [];
            }
            catch (err) {
                console.error(err);
                return [];
            }
        });
    }
    function deleteItem(path, key) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const url = `${baseUrl}/${routePrefix}/${path}`;
                const session = yield Auth.currentSession();
                const accessToken = session.getAccessToken().getJwtToken();
                const response = yield axios.delete(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const data = response.data[key];
                return data;
            }
            catch (err) {
                console.error(err);
                return null;
            }
        });
    }
    function getBoxes(boxVisibility, boxNamePrefix = '') {
        const prefixPath = boxNamePrefix ? `/${boxNamePrefix}` : '';
        let visibilityPath;
        switch (boxVisibility) {
            case BoxVisibility.PUBLIC:
                visibilityPath = 'public';
                break;
            case BoxVisibility.PRIVATE:
                visibilityPath = 'private';
                break;
            case BoxVisibility.ALL:
                visibilityPath = 'all';
                break;
            default:
                visibilityPath = 'all';
                break;
        }
        const boxes = getList(`${visibilityPath}${prefixPath}`, 'boxes');
        return boxes;
    }
    function deleteBox(boxName) {
        return __awaiter(this, void 0, void 0, function* () {
            const path = `private/${boxName}`;
            const deleted = yield deleteItem(path, 'boxes');
            if (!deleted) {
                return false;
            }
            return true;
        });
    }
    function deleteBoxVersion(boxName, boxVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            const path = `private/${boxName}/${boxVersion}`;
            const deletedVersion = yield deleteItem(path, 'box');
            if (!deletedVersion) {
                return false;
            }
            return true;
        });
    }
    function downloadBox(boxVisibility, boxName, boxVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            const path = `${boxVisibility}/${boxName}/${boxVersion}/download`;
            const downloadUrl = yield getItem(path, 'url');
            if (!downloadUrl) {
                return false;
            }
            const link = document.createElement('a');
            link.href = downloadUrl;
            const [boxNamePrefix, boxNameSuffix] = boxName.split('/');
            const fileName = `${boxNamePrefix}-${boxNameSuffix}-${boxVersion}.box`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return true;
        });
    }
    function uploadBox(formData, boxFile, onUploadProgress) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const s3InfoPath = `private/${formData.boxName}/${formData.boxVersion}/s3info`;
                const s3info = yield getItem(s3InfoPath, 's3info');
                if (!s3info) {
                    return false;
                }
                const currentSession = yield Auth.currentSession();
                const jwt = currentSession.getIdToken().getJwtToken();
                AWS.config.region = userPoolRegion;
                const credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: identityPoolId,
                    Logins: {
                        [`cognito-idp.${userPoolRegion}.amazonaws.com/${userPoolId}`]: jwt,
                    },
                });
                const s3Client = new AWS.S3({
                    apiVersion: '2006-03-01',
                    region: userPoolRegion,
                    credentials,
                });
                const params = {
                    service: s3Client,
                    params: {
                        Key: s3info.object_key,
                        Bucket: s3info.bucket_name,
                        Body: boxFile,
                        ContentType: boxFile.type,
                    },
                };
                const managedUpload = new AWS.S3.ManagedUpload(params);
                if (onUploadProgress) {
                    const handleUploadProgress = (progress) => {
                        if (!progress.loaded || !progress.total) {
                            return onUploadProgress(0);
                        }
                        const amount = (progress.loaded / progress.total) * 100;
                        // this strange code is used to avoid issues with rounding
                        // see: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
                        const percent = Math.round((amount + Number.EPSILON) * 100) / 100;
                        onUploadProgress(percent);
                    };
                    managedUpload.on('httpUploadProgress', handleUploadProgress);
                }
                yield managedUpload.promise();
                return true;
            }
            catch (err) {
                console.error(err);
                return false;
            }
        });
    }
    return {
        getBoxes,
        deleteBox,
        deleteBoxVersion,
        downloadBox,
        uploadBox,
    };
}
