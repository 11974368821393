var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Page from '../../components/page_skeleton';
// eslint-disable-next-line @typescript-eslint/naming-convention
const Component = React.lazy(() => __awaiter(void 0, void 0, void 0, function* () {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { Templates } = yield import('./templates');
    return { default: Templates };
}));
// eslint-disable-next-line @typescript-eslint/naming-convention
const WrappedPage = () => {
    return (_jsx(Page, { children: _jsx(Component, {}) }));
};
export default WrappedPage;
