// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HJMx0369336JBtvU9BsQ .ui.header {\n\tcolor: #201747;\n}\n\n.HJMx0369336JBtvU9BsQ .item {\n\theight: 100%;\n\tmargin-left: 15px;\n\tmargin-right: 15px;\n}\n\n.HJMx0369336JBtvU9BsQ .item.active {\n\theight: 100%;\n\n\t/* color: #201747 !important; */\n\t/* border-color: #d3d2d2 !important;\n  border-width: 5px !important; */\n}\n\n#default_layout {\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100vh;\n\toverflow: hidden;\n}\n\n#default_layout .menu {\n\tmargin-bottom: 0px;\n}\n\n.P124lIdb4GKA_h6k1W_G {\n\tflex-grow: 1;\n\toverflow: auto;\n\tpadding: 0 !important; /* Remove padding */\n\twidth: 100%; /* Ensure it takes up the full width */\n\tmax-width: 100%; /* Override any max-width properties */\n}\n", "",{"version":3,"sources":["webpack://./src/layouts/default_layout.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;AACf;;AAEA;CACC,YAAY;CACZ,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,YAAY;;CAEZ,+BAA+B;CAC/B;iCACgC;AACjC;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,aAAa;CACb,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,cAAc;CACd,qBAAqB,EAAE,mBAAmB;CAC1C,WAAW,EAAE,sCAAsC;CACnD,eAAe,EAAE,sCAAsC;AACxD","sourcesContent":[".navbar :global(.ui.header) {\n\tcolor: #201747;\n}\n\n.navbar :global(.item) {\n\theight: 100%;\n\tmargin-left: 15px;\n\tmargin-right: 15px;\n}\n\n.navbar :global(.item):global(.active) {\n\theight: 100%;\n\n\t/* color: #201747 !important; */\n\t/* border-color: #d3d2d2 !important;\n  border-width: 5px !important; */\n}\n\n:global(#default_layout) {\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100vh;\n\toverflow: hidden;\n}\n\n:global(#default_layout .menu) {\n\tmargin-bottom: 0px;\n}\n\n.content {\n\tflex-grow: 1;\n\toverflow: auto;\n\tpadding: 0 !important; /* Remove padding */\n\twidth: 100%; /* Ensure it takes up the full width */\n\tmax-width: 100%; /* Override any max-width properties */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "HJMx0369336JBtvU9BsQ",
	"content": "P124lIdb4GKA_h6k1W_G"
};
export default ___CSS_LOADER_EXPORT___;
