var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-semantic-toasts';
import { Button, Card, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { api } from '../../api';
import { getCollection } from '../../utils';
import { CreateUserModal } from '../modals/new_user_modal';
import classes from './members.module.css';
import UserTable from './UserTable';
export default function MembersList() {
    const { isLoading: usermembersLoading, data: members, refetch, } = useQuery('users', () => api.users.listUsers());
    const [membersState, setMembersState] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState(null);
    const [addMemberModal, setAddMemberModal] = useState(false);
    useEffect(() => {
        if (usermembersLoading) {
            if (membersState.length > 0) {
                setMembersState([]);
            }
            return;
        }
        if (members === null || members === void 0 ? void 0 : members.data) {
            return setMembersState(members === null || members === void 0 ? void 0 : members.data);
        }
        setMembersState([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usermembersLoading, members === null || members === void 0 ? void 0 : members.data]);
    function handleSelected(email) {
        const eachStudent = membersState.find((student) => student.email === email);
        if (!eachStudent || email === (selectedMembers === null || selectedMembers === void 0 ? void 0 : selectedMembers.email)) {
            setSelectedMembers(null);
        }
        else {
            setSelectedMembers(eachStudent);
        }
    }
    const handleOpenAddStudentModal = () => {
        setAddMemberModal(true);
    };
    const handleCloseAddStudentModal = () => {
        setAddMemberModal(false);
    };
    const mutDelete = useMutation((username) => api.users.deleteUser(username), {
        onSuccess: () => __awaiter(this, void 0, void 0, function* () {
            yield refetch();
            setSelectedMembers(null);
            toast({
                type: 'success',
                title: 'Member deleted',
            });
        }),
        onError: (error) => {
            var _a;
            toast({
                type: 'error',
                title: 'Failed to delete member',
                description: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.detail,
            });
        },
    });
    return (_jsx("div", { children: _jsxs(Card, Object.assign({ fluid: true }, { children: [_jsx(Card.Content, { children: _jsx(Card.Header, { children: "Team Members" }) }), _jsxs(Card.Content, { children: [_jsx(Button, Object.assign({ primary: true, color: "green", onClick: handleOpenAddStudentModal }, { children: "Add" })), _jsx(Button, Object.assign({ color: "red", disabled: selectedMembers === null, loading: usermembersLoading, onClick: () => mutDelete.mutateAsync(selectedMembers === null || selectedMembers === void 0 ? void 0 : selectedMembers.username) }, { children: "Delete" }))] }), _jsx(CreateUserModal, { open: addMemberModal, onClose: handleCloseAddStudentModal }), _jsx("div", Object.assign({ className: classes.tableContent }, { children: usermembersLoading ? (_jsx(Segment, Object.assign({ className: classes.loadingContent }, { children: _jsx(Dimmer, Object.assign({ active: true, inverted: true }, { children: _jsx(Loader, Object.assign({ inverted: true }, { children: "Loading members" })) })) }))) : (_jsx(UserTable, { rowsPerPage: 15, users: getCollection(members === null || members === void 0 ? void 0 : members.data), handleSelected: handleSelected })) }))] })) }));
}
