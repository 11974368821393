import FlexLayout from 'flexlayout-react';
export function getNodeAttributes(node) {
    return node._attributes;
}
export function defaultFlexLayoutContext(layoutRef) {
    return {
        model: defaultFlexModel(),
        layout: layoutRef || {},
        activateTicket: () => { },
        deleteTab: () => { },
        refreshTab: () => { },
        updateTabScreenSize: () => { },
    };
}
export function defaultFlexModel(layoutData) {
    return FlexLayout.Model.fromJson(layoutData || emptyFlexLayout());
}
function emptyFlexLayout() {
    return {
        global: {
            splitterSize: 5,
            tabEnableRename: false,
            tabSetHeaderHeight: 25,
            tabSetTabStripHeight: 25,
        },
        layout: {
            type: 'row',
            weight: 100,
            children: [],
        },
        borders: [
            {
                type: 'border',
                selected: 0,
                location: 'left',
                show: 'true',
                enableDrop: 'false',
                size: 250,
                children: [],
            },
        ],
    };
}
export function defaultAdminFlexLayout(environmentId) {
    return {
        global: {
            splitterSize: 5,
            tabEnableRename: false,
            tabSetHeaderHeight: 25,
            tabSetTabStripHeight: 25,
        },
        layout: {
            type: 'row',
            weight: 100,
            children: [
                {
                    type: 'tabset',
                    selected: 0,
                    id: 'tabset_main',
                    children: [
                        {
                            type: 'tab',
                            name: 'Overview',
                            id: 'overview',
                            component: 'Overview',
                            enableClose: false,
                            enableDrag: false,
                            config: {
                                environmentId,
                                isReadonly: false,
                            },
                        },
                    ],
                },
            ],
        },
        borders: [
            {
                type: 'border',
                selected: 0,
                location: 'left',
                show: 'true',
                enableDrop: 'false',
                size: 250,
                children: [
                    {
                        type: 'tab',
                        name: 'Connections',
                        id: 'border_left_connections',
                        enableDrag: false,
                        enableClose: false,
                        component: 'ConnectionSidebar',
                    },
                    {
                        type: 'tab',
                        name: 'Tasks',
                        id: 'border_left_tasks',
                        enableDrag: false,
                        enableClose: false,
                        component: 'TasksSidebar',
                    },
                ],
            },
        ],
    };
}
export function defaultUserFlexLayout(environmentId) {
    return {
        global: {
            splitterSize: 5,
            tabEnableRename: false,
            tabSetHeaderHeight: 25,
            tabSetTabStripHeight: 25,
        },
        layout: {
            type: 'row',
            weight: 100,
            children: [
                {
                    type: 'tabset',
                    selected: 0,
                    id: 'tabset_main',
                    children: [
                        {
                            type: 'tab',
                            name: 'Overview',
                            id: 'overview',
                            component: 'Overview',
                            enableClose: false,
                            enableDrag: false,
                            config: {
                                environmentId,
                                isReadonly: true,
                            },
                        },
                    ],
                },
            ],
        },
        borders: [
            {
                type: 'border',
                selected: 0,
                location: 'left',
                show: 'true',
                enableDrop: 'false',
                size: 250,
                children: [
                    {
                        type: 'tab',
                        name: 'Connections',
                        id: 'border_left_connections',
                        enableDrag: false,
                        enableClose: false,
                        component: 'ConnectionSidebar',
                    },
                ],
            },
        ],
    };
}
