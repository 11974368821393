export function sortByStringField(field, direction) {
    if (direction === 'ascending') {
        return function sortAscending({ [field]: l }, { [field]: r }) {
            return l.localeCompare(r);
        };
    }
    return function sortDescending({ [field]: l }, { [field]: r }) {
        return -l.localeCompare(r);
    };
}
export function sortByNumberField(field, direction) {
    if (direction === 'ascending') {
        return function sortAscending({ [field]: l }, { [field]: r }) {
            return r - l;
        };
    }
    return function sortDescending({ [field]: l }, { [field]: r }) {
        return l - r;
    };
}
export function sortByLinkField(field, direction) {
    if (direction === 'ascending') {
        return function sortAscending({ [field]: l }, { [field]: r }) {
            return l.label.localeCompare(r.label);
        };
    }
    return function sortDescending({ [field]: l }, { [field]: r }) {
        return -l.label.localeCompare(r.label);
    };
}
export function sortByBooleanField(field, direction) {
    if (direction === 'ascending') {
        return function sortAscending({ [field]: l }, { [field]: r }) {
            return Number(r) - Number(l);
        };
    }
    return function sortDescending({ [field]: l }, { [field]: r }) {
        return Number(l) - Number(r);
        // -l.localeCompare(r);
        // (x, y) => Number(x) - Number(y)
        // (a, b) => b.localeCompare(a)
    };
}
