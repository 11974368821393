import { jsx as _jsx } from "react/jsx-runtime";
import { Amplify } from 'aws-amplify';
import 'flexlayout-react/style/light.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import 'semantic-ui-less/semantic.less';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
const amplifyConfig = {
    Auth: {
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        region: 'us-east-1',
        oauth: {
            scope: ['com.ccpams.dev'],
        },
    },
    API: {
        endpoints: [
            {
                name: 'backend',
                endpoint: process.env.REACT_APP_API_URL,
            },
        ],
    },
};
Amplify.configure(amplifyConfig);
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(App, {}) }), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
