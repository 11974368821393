const awsconfig = {
    Auth: {
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        region: 'us-east-1',
        oauth: {
            scope: ['com.ccpams.dev'],
        },
    },
    API: {
        endpoints: [
            {
                name: 'backend',
                endpoint: process.env.REACT_APP_API_URL,
            },
        ],
    },
};
export default awsconfig;
