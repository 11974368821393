import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Authenticator, Button, CheckboxField, Heading, Image, Text, ThemeProvider, useAuthenticator, useTheme, View, } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Redirect, Route, Switch, } from 'react-router-dom';
import { SemanticToastContainer } from 'react-semantic-toasts';
import awsconfig from './aws-exports';
import AuthProvider from './context/auth_context';
import { BoxesProvider } from './context/boxes_context';
import DefaultLayout from './layouts/default';
import Error404 from './pages/404';
import AI from './pages/AI';
import BoxesPage from './pages/Boxes';
import Dashboard from './pages/Dashboard';
import Data from './pages/Data';
import Environments from './pages/Environments';
import Settings from './pages/Settings/settings.page';
import Templates from './pages/Templates';
import { synthwaveTheme } from './themes/synthwave';
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Refetch every 5 minutes
            refetchInterval: 1000 * 60 * 5,
        },
    },
});
Amplify.configure(awsconfig);
const components = {
    Header() {
        const { tokens } = useTheme();
        return (_jsx(View, Object.assign({ textAlign: "center", padding: tokens.space.large }, { children: _jsx(Image, { alt: "CastleClone logo", src: "images/logo.png" }) })));
    },
    Footer() {
        const { tokens } = useTheme();
        return (_jsx(View, Object.assign({ textAlign: "center", padding: tokens.space.large }, { children: _jsx(Text, Object.assign({ color: tokens.colors.neutral[80] }, { children: "\u00A9 2023 Leidos, All Rights Reserved" })) })));
    },
    SignIn: {
        Header() {
            const { tokens } = useTheme();
            return (_jsx(Heading, Object.assign({ padding: `${tokens.space.xl} 0 0 ${tokens.space.xl}`, level: 3 }, { children: "Sign in to your account" })));
        },
        Footer() {
            const { toResetPassword } = useAuthenticator();
            return (_jsx(View, Object.assign({ textAlign: "center" }, { children: _jsx(Button, Object.assign({ fontWeight: "normal", onClick: toResetPassword, size: "small", variation: "link" }, { children: "Reset Password" })) })));
        },
    },
    SignUp: {
        Header() {
            const { tokens } = useTheme();
            return (_jsx(Heading, Object.assign({ padding: `${tokens.space.xl} 0 0 ${tokens.space.xl}`, level: 3 }, { children: "Create a new account" })));
        },
        Footer() {
            const { toSignIn } = useAuthenticator();
            return (_jsx(View, Object.assign({ textAlign: "center" }, { children: _jsx(Button, Object.assign({ fontWeight: "normal", onClick: toSignIn, size: "small", variation: "link" }, { children: "Back to Sign In" })) })));
        },
        FormFields() {
            const { validationErrors } = useAuthenticator();
            return (_jsxs(_Fragment, { children: [_jsx(Authenticator.SignUp.FormFields, {}), _jsx(CheckboxField, { errorMessage: validationErrors.acknowledgement, hasError: !!validationErrors.acknowledgement, name: "acknowledgement", value: "yes", label: "I agree with the Terms & Conditions" })] }));
        },
    },
    ConfirmSignUp: {
        Header() {
            const { tokens } = useTheme();
            return (_jsx(Heading, Object.assign({ padding: `${tokens.space.xl} 0 0 ${tokens.space.xl}`, level: 3 }, { children: "Enter Information:" })));
        },
        Footer() {
            return _jsx(Text, { children: "Footer Information" });
        },
    },
    SetupTOTP: {
        Header() {
            const { tokens } = useTheme();
            return (_jsx(Heading, Object.assign({ padding: `${tokens.space.xl} 0 0 ${tokens.space.xl}`, level: 3 }, { children: "Enter Information:" })));
        },
        Footer() {
            return _jsx(Text, { children: "Footer Information" });
        },
    },
    ConfirmSignIn: {
        Header() {
            const { tokens } = useTheme();
            return (_jsx(Heading, Object.assign({ padding: `${tokens.space.xl} 0 0 ${tokens.space.xl}`, level: 3 }, { children: "Enter Information:" })));
        },
        Footer() {
            return _jsx(Text, { children: "Footer Information" });
        },
    },
    ResetPassword: {
        Header() {
            const { tokens } = useTheme();
            return (_jsx(Heading, Object.assign({ padding: `${tokens.space.xl} 0 0 ${tokens.space.xl}`, level: 3 }, { children: "Enter Information:" })));
        },
        Footer() {
            return _jsx(Text, { children: "Footer Information" });
        },
    },
    ConfirmResetPassword: {
        Header() {
            const { tokens } = useTheme();
            return (_jsx(Heading, Object.assign({ padding: `${tokens.space.xl} 0 0 ${tokens.space.xl}`, level: 3 }, { children: "Enter Information:" })));
        },
        Footer() {
            return _jsx(Text, { children: "Footer Information" });
        },
    },
};
function App() {
    return (_jsx(ThemeProvider, Object.assign({ theme: synthwaveTheme, colorMode: "system" }, { children: _jsx(Authenticator, Object.assign({ components: components }, { children: _jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: _jsx(AuthProvider, { children: _jsxs(BoxesProvider, { children: [_jsx(SemanticToastContainer, {}), _jsx(Routes, {})] }) }) })) })) })));
}
function Routes() {
    const onChange = function (value) {
        console.log(value);
    };
    return (_jsx(Router, { children: _jsxs(Switch, { children: [_jsx(Route, Object.assign({ path: "/", exact: true }, { children: _jsx(Redirect, { to: "/dashboard" }) })), _jsx(Route, Object.assign({ path: "/dashboard" }, { children: _jsx(DefaultLayout, { children: _jsx(Dashboard, {}) }) })), _jsx(Route, Object.assign({ path: "/settings" }, { children: _jsx(DefaultLayout, { children: _jsx(Settings, {}) }) })), _jsx(Route, Object.assign({ path: "/data" }, { children: _jsx(DefaultLayout, { children: _jsx(Data, {}) }) })), _jsx(Route, Object.assign({ path: "/boxes" }, { children: _jsx(DefaultLayout, { children: _jsx(BoxesPage, {}) }) })), _jsx(Route, Object.assign({ path: "/ai" }, { children: _jsx(DefaultLayout, { children: _jsx(AI, {}) }) })), _jsx(Route, Object.assign({ path: "/environments" }, { children: _jsx(DefaultLayout, { children: _jsx(Environments, {}) }) })), _jsx(Route, Object.assign({ path: "/templates" }, { children: _jsx(DefaultLayout, { children: _jsx(Templates, {}) }) })), _jsx(Route, Object.assign({ path: "/" }, { children: _jsx(Error404, {}) }))] }) }));
}
export default App;
