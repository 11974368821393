import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import { BoxService } from '../services/box.service';
import { BoxVisibility } from '../types';
const defaultContext = {
    boxes: [],
    isLoading: true,
    error: null,
    refreshBoxes: () => { },
};
export const BoxesContext = createContext(defaultContext);
export const useBoxesContext = () => useContext(BoxesContext);
export const BoxesProvider = ({ children }) => {
    const [boxes, setBoxes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const refreshBoxes = () => {
        setIsLoading(true);
        BoxService()
            .getBoxes(BoxVisibility.ALL)
            .then((data) => {
            setBoxes(data);
            setIsLoading(false);
            setError(null);
        })
            .catch((err) => {
            setIsLoading(false);
            setError(err);
        });
    };
    useEffect(() => {
        refreshBoxes();
    }, []);
    const value = { boxes, isLoading, error, refreshBoxes };
    return (_jsx(BoxesContext.Provider, Object.assign({ value: value }, { children: children })));
};
