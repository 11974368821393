var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { Auth } from '@aws-amplify/auth';
import { BucketsApi, BudgetsApi, Configuration, CostsApi, EnvironmentsApi, NotebooksApi, ScenariosApi, TasksApi, TemplatesApi, UsersApi, } from './gen';
const TOKEN_KEY = 'ccpass_u';
const configuration = new Configuration({
    basePath: process.env.REACT_APP_API_URL,
    accessToken: (_a = localStorage.getItem(TOKEN_KEY)) !== null && _a !== void 0 ? _a : undefined,
});
function addAuthHeader(init) {
    return __awaiter(this, void 0, void 0, function* () {
        const session = yield Auth.currentSession();
        const requestInit = Object.assign(Object.assign({}, init), { headers: Object.assign(Object.assign({}, init === null || init === void 0 ? void 0 : init.headers), { Authorization: 'Bearer ' + session.getIdToken().getJwtToken() }), redirect: 'follow' });
        return requestInit;
    });
}
function customFetch(input, init) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(input, yield addAuthHeader(init));
        if (response.redirected) {
            console.log(response.status);
        }
        if (response.status === 401 || response.status === 403) {
            Auth.signOut();
            window.location.reload();
        }
        return response;
    });
}
export const api = {
    environments: new EnvironmentsApi(configuration, '', customFetch),
    buckets: new BucketsApi(configuration, '', customFetch),
    notebooks: new NotebooksApi(configuration, '', customFetch),
    templates: new TemplatesApi(configuration, '', customFetch),
    tasks: new TasksApi(configuration, '', customFetch),
    scenarios: new ScenariosApi(configuration, '', customFetch),
    users: new UsersApi(configuration, '', customFetch),
    costs: new CostsApi(configuration, '', customFetch),
    budget: new BudgetsApi(configuration, '', customFetch),
};
