var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { get, useFormContext } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import ErrorMessage from './error_message';
import classes from './form.module.css';
export const BaseTextInput = React.forwardRef((_a, ref) => {
    var { name, label, errors, className } = _a, props = __rest(_a, ["name", "label", "errors", "className"]);
    const error = get(errors || {}, name);
    return (_jsxs(Form.Field, Object.assign({ className: className, error: !!error }, { children: [label && _jsx("label", { children: label }), _jsx("input", Object.assign({}, props, { className: classes.textInput, ref: ref, name: name })), error && _jsx(ErrorMessage, { children: error.message })] })));
});
const TextInput = React.forwardRef((props, ref) => {
    const { formState: { errors }, } = useFormContext();
    return _jsx(BaseTextInput, Object.assign({ errors: errors }, props, { ref: ref }));
});
export default TextInput;
