import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import PageErrorBoundary from './page_error_boundry';
const Page = ({ loader, children }) => {
    return (_jsx(PageErrorBoundary, { children: _jsx(Segment, Object.assign({ basic: true, style: {
                height: '100%',
                width: '100%',
            } }, { children: _jsx(Suspense, Object.assign({ fallback: loader !== null && loader !== void 0 ? loader : (_jsx(Dimmer, Object.assign({ active: true, inverted: true }, { children: _jsx(Loader, {}) }))) }, { children: children })) })) }));
};
export default Page;
