export function unknownValue(value, ret) {
    return ret;
}
export function onClick(callback) {
    function onClick(event) {
        event.preventDefault();
        event.stopPropagation();
        callback(event);
    }
    return onClick;
}
export function onInputChange(callback) {
    function onChange(event) {
        event.preventDefault();
        callback(event.target.value);
    }
    return onChange;
}
export function range(count) {
    const values = [];
    for (let i = 0; i < count; i++) {
        values[i] = i;
    }
    return values;
}
