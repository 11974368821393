// tslint:disable
/**
 * CastleClone
 * CastleClone API Server
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */
import isomorphicFetch from 'isomorphic-fetch';
import * as url from 'url';
const BASE_PATH = '/'.replace(/\/+$/, '');
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|',
};
/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    constructor(configuration, basePath = BASE_PATH, fetch = isomorphicFetch) {
        this.basePath = basePath;
        this.fetch = fetch;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    constructor(field, msg) {
        super(msg);
        this.field = field;
        this.name = 'RequiredError';
    }
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export var ActionType;
(function (ActionType) {
    ActionType["RunShellScript"] = "runShellScript";
    ActionType["RunAnsiblePlaybook"] = "runAnsiblePlaybook";
})(ActionType || (ActionType = {}));
/**
 * The network type object
 * @export
 * @enum {string}
 */
export var CcpaasCoreUtilsEnumsNetworkType;
(function (CcpaasCoreUtilsEnumsNetworkType) {
    CcpaasCoreUtilsEnumsNetworkType["Private"] = "private";
    CcpaasCoreUtilsEnumsNetworkType["Public"] = "public";
})(CcpaasCoreUtilsEnumsNetworkType || (CcpaasCoreUtilsEnumsNetworkType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export var CcpaasCoreUtilsTemplatesVmNetworkType;
(function (CcpaasCoreUtilsTemplatesVmNetworkType) {
    CcpaasCoreUtilsTemplatesVmNetworkType["Private"] = "private";
    CcpaasCoreUtilsTemplatesVmNetworkType["Public"] = "public";
})(CcpaasCoreUtilsTemplatesVmNetworkType || (CcpaasCoreUtilsTemplatesVmNetworkType = {}));
/**
 * A type of container object
 * @export
 * @enum {string}
 */
export var ContainerKind;
(function (ContainerKind) {
    ContainerKind["Image"] = "image";
    ContainerKind["Template"] = "template";
})(ContainerKind || (ContainerKind = {}));
/**
 * A container status object
 * @export
 * @enum {string}
 */
export var ContainerStatus;
(function (ContainerStatus) {
    ContainerStatus["Deploying"] = "deploying";
    ContainerStatus["PreparingVms"] = "preparing_vms";
    ContainerStatus["Ready"] = "ready";
    ContainerStatus["Removing"] = "removing";
})(ContainerStatus || (ContainerStatus = {}));
/**
 * An environment state object
 * @export
 * @enum {string}
 */
export var EnvironmentState;
(function (EnvironmentState) {
    EnvironmentState["Deploying"] = "deploying";
    EnvironmentState["Registering"] = "registering";
    EnvironmentState["Removing"] = "removing";
    EnvironmentState["Ready"] = "ready";
})(EnvironmentState || (EnvironmentState = {}));
/**
 * Different environment types
 * @export
 * @enum {string}
 */
export var EnvironmentType;
(function (EnvironmentType) {
    EnvironmentType["Virtualbox"] = "virtualbox";
    EnvironmentType["Libvirt"] = "libvirt";
    EnvironmentType["Eks"] = "eks";
})(EnvironmentType || (EnvironmentType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export var ExecutionState;
(function (ExecutionState) {
    ExecutionState["NotStarted"] = "not_started";
    ExecutionState["Running"] = "running";
    ExecutionState["Finished"] = "finished";
    ExecutionState["Failed"] = "failed";
})(ExecutionState || (ExecutionState = {}));
/**
 * The network type object
 * @export
 * @enum {string}
 */
export var NetworkType;
(function (NetworkType) {
    NetworkType["Private"] = "private";
    NetworkType["Public"] = "public";
})(NetworkType || (NetworkType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export var ParameterType;
(function (ParameterType) {
    ParameterType["Integer"] = "integer";
    ParameterType["String"] = "string";
})(ParameterType || (ParameterType = {}));
/**
 * Container disk types
 * @export
 * @enum {string}
 */
export var PodVMBoxType;
(function (PodVMBoxType) {
    PodVMBoxType["Image"] = "image";
})(PodVMBoxType || (PodVMBoxType = {}));
/**
 * Status of the vmi
 * @export
 * @enum {string}
 */
export var PodVMStatus;
(function (PodVMStatus) {
    PodVMStatus["Creating"] = "creating";
    PodVMStatus["Deleting"] = "deleting";
    PodVMStatus["Running"] = "running";
    PodVMStatus["Stopped"] = "stopped";
    PodVMStatus["Error"] = "error";
})(PodVMStatus || (PodVMStatus = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export var Protocol;
(function (Protocol) {
    Protocol["Vnc"] = "vnc";
    Protocol["Rdp"] = "rdp";
    Protocol["Ssh"] = "ssh";
})(Protocol || (Protocol = {}));
/**
 * A remote protocol object
 * @export
 * @enum {string}
 */
export var RemoteProtocol;
(function (RemoteProtocol) {
    RemoteProtocol["Rdp"] = "rdp";
    RemoteProtocol["Vnc"] = "vnc";
    RemoteProtocol["Ssh"] = "ssh";
})(RemoteProtocol || (RemoteProtocol = {}));
/**
 * The status for a scenario's lifecycle states
 * @export
 * @enum {string}
 */
export var ScenarioStatus;
(function (ScenarioStatus) {
    ScenarioStatus["DeployingEnvironment"] = "deploying_environment";
    ScenarioStatus["DeployingSubnets"] = "deploying_subnets";
    ScenarioStatus["DeployingContainers"] = "deploying_containers";
    ScenarioStatus["RunningTasks"] = "running_tasks";
    ScenarioStatus["Ready"] = "ready";
    ScenarioStatus["Removing"] = "removing";
    ScenarioStatus["Error"] = "error";
})(ScenarioStatus || (ScenarioStatus = {}));
/**
 * The different ways that a task can be started.
 * @export
 * @enum {string}
 */
export var TaskTriggerType;
(function (TaskTriggerType) {
    TaskTriggerType["Manual"] = "manual";
    TaskTriggerType["Auto"] = "auto";
    TaskTriggerType["Delay"] = "delay";
})(TaskTriggerType || (TaskTriggerType = {}));
/**
 * A template type object
 * @export
 * @enum {string}
 */
export var TemplateType;
(function (TemplateType) {
    TemplateType["VmContainer"] = "vm_container";
    TemplateType["Task"] = "task";
    TemplateType["Scenario"] = "scenario";
    TemplateType["PodVm"] = "pod_vm";
})(TemplateType || (TemplateType = {}));
/**
 * Different roles for users
 * @export
 * @enum {string}
 */
export var UserRole;
(function (UserRole) {
    UserRole["Student"] = "student";
    UserRole["Admin"] = "admin";
})(UserRole || (UserRole = {}));
/**
 * BucketsApi - fetch parameter creator
 * @export
 */
export const BucketsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * API route to create a new bucket  Returns:     :Response: API response
         * @summary Create Bucket
         * @param {CreateBucketData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBucket(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createBucket.');
            }
            const localVarPath = `/buckets/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = 'CreateBucketData' !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] ===
                    'application/json';
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || '';
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to delete a given data bucket  Returns:     :Response: API response
         * @summary Delete Bucket
         * @param {string} bucket_name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBucket(bucket_name, options = {}) {
            // verify required parameter 'bucket_name' is not null or undefined
            if (bucket_name === null || bucket_name === undefined) {
                throw new RequiredError('bucket_name', 'Required parameter bucket_name was null or undefined when calling deleteBucket.');
            }
            const localVarPath = `/buckets/{bucket_name}`.replace(`{${'bucket_name'}}`, encodeURIComponent(String(bucket_name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to delete a given file or folder  Returns:     :Response: API response
         * @summary Delete File
         * @param {string} bucket_name
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(bucket_name, filename, options = {}) {
            // verify required parameter 'bucket_name' is not null or undefined
            if (bucket_name === null || bucket_name === undefined) {
                throw new RequiredError('bucket_name', 'Required parameter bucket_name was null or undefined when calling deleteFile.');
            }
            // verify required parameter 'filename' is not null or undefined
            if (filename === null || filename === undefined) {
                throw new RequiredError('filename', 'Required parameter filename was null or undefined when calling deleteFile.');
            }
            const localVarPath = `/buckets/{bucket_name}/contents/{filename}`
                .replace(`{${'bucket_name'}}`, encodeURIComponent(String(bucket_name)))
                .replace(`{${'filename'}}`, encodeURIComponent(String(filename)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to list contents of bucket  Returns:     :Response: API response
         * @summary Get Bucket Contents
         * @param {string} bucket_name
         * @param {string} prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBucketContents(bucket_name, prefix, options = {}) {
            // verify required parameter 'bucket_name' is not null or undefined
            if (bucket_name === null || bucket_name === undefined) {
                throw new RequiredError('bucket_name', 'Required parameter bucket_name was null or undefined when calling getBucketContents.');
            }
            // verify required parameter 'prefix' is not null or undefined
            if (prefix === null || prefix === undefined) {
                throw new RequiredError('prefix', 'Required parameter prefix was null or undefined when calling getBucketContents.');
            }
            const localVarPath = `/buckets/{bucket_name}/contents/{prefix}`
                .replace(`{${'bucket_name'}}`, encodeURIComponent(String(bucket_name)))
                .replace(`{${'prefix'}}`, encodeURIComponent(String(prefix)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to list all data buckets  Returns:     :Response: API response
         * @summary Get Buckets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuckets(options = {}) {
            const localVarPath = `/buckets/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to get presigned get URL  Returns:     :Response: API response
         * @summary Get Download Url
         * @param {string} bucket_name
         * @param {string} prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl(bucket_name, prefix, options = {}) {
            // verify required parameter 'bucket_name' is not null or undefined
            if (bucket_name === null || bucket_name === undefined) {
                throw new RequiredError('bucket_name', 'Required parameter bucket_name was null or undefined when calling getDownloadUrl.');
            }
            // verify required parameter 'prefix' is not null or undefined
            if (prefix === null || prefix === undefined) {
                throw new RequiredError('prefix', 'Required parameter prefix was null or undefined when calling getDownloadUrl.');
            }
            const localVarPath = `/buckets/{bucket_name}/download/{prefix}`
                .replace(`{${'bucket_name'}}`, encodeURIComponent(String(bucket_name)))
                .replace(`{${'prefix'}}`, encodeURIComponent(String(prefix)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to get an upload URL  Returns:     :Response: API response
         * @summary Get Upload Url
         * @param {string} bucket_name
         * @param {string} filename
         * @param {string} [content_type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadUrl(bucket_name, filename, content_type, options = {}) {
            // verify required parameter 'bucket_name' is not null or undefined
            if (bucket_name === null || bucket_name === undefined) {
                throw new RequiredError('bucket_name', 'Required parameter bucket_name was null or undefined when calling getUploadUrl.');
            }
            // verify required parameter 'filename' is not null or undefined
            if (filename === null || filename === undefined) {
                throw new RequiredError('filename', 'Required parameter filename was null or undefined when calling getUploadUrl.');
            }
            const localVarPath = `/buckets/{bucket_name}/contents/{filename}`
                .replace(`{${'bucket_name'}}`, encodeURIComponent(String(bucket_name)))
                .replace(`{${'filename'}}`, encodeURIComponent(String(filename)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (content_type !== undefined && content_type !== null) {
                localVarHeaderParameter['content-type'] = String(content_type);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BucketsApi - functional programming interface
 * @export
 */
export const BucketsApiFp = function (configuration) {
    return {
        /**
         * API route to create a new bucket  Returns:     :Response: API response
         * @summary Create Bucket
         * @param {CreateBucketData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBucket(body, options) {
            const localVarFetchArgs = BucketsApiFetchParamCreator(configuration).createBucket(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to delete a given data bucket  Returns:     :Response: API response
         * @summary Delete Bucket
         * @param {string} bucket_name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBucket(bucket_name, options) {
            const localVarFetchArgs = BucketsApiFetchParamCreator(configuration).deleteBucket(bucket_name, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to delete a given file or folder  Returns:     :Response: API response
         * @summary Delete File
         * @param {string} bucket_name
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(bucket_name, filename, options) {
            const localVarFetchArgs = BucketsApiFetchParamCreator(configuration).deleteFile(bucket_name, filename, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to list contents of bucket  Returns:     :Response: API response
         * @summary Get Bucket Contents
         * @param {string} bucket_name
         * @param {string} prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBucketContents(bucket_name, prefix, options) {
            const localVarFetchArgs = BucketsApiFetchParamCreator(configuration).getBucketContents(bucket_name, prefix, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to list all data buckets  Returns:     :Response: API response
         * @summary Get Buckets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuckets(options) {
            const localVarFetchArgs = BucketsApiFetchParamCreator(configuration).getBuckets(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to get presigned get URL  Returns:     :Response: API response
         * @summary Get Download Url
         * @param {string} bucket_name
         * @param {string} prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl(bucket_name, prefix, options) {
            const localVarFetchArgs = BucketsApiFetchParamCreator(configuration).getDownloadUrl(bucket_name, prefix, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to get an upload URL  Returns:     :Response: API response
         * @summary Get Upload Url
         * @param {string} bucket_name
         * @param {string} filename
         * @param {string} [content_type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadUrl(bucket_name, filename, content_type, options) {
            const localVarFetchArgs = BucketsApiFetchParamCreator(configuration).getUploadUrl(bucket_name, filename, content_type, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * BucketsApi - factory interface
 * @export
 */
export const BucketsApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * API route to create a new bucket  Returns:     :Response: API response
         * @summary Create Bucket
         * @param {CreateBucketData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBucket(body, options) {
            return BucketsApiFp(configuration).createBucket(body, options)(fetch, basePath);
        },
        /**
         * API route to delete a given data bucket  Returns:     :Response: API response
         * @summary Delete Bucket
         * @param {string} bucket_name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBucket(bucket_name, options) {
            return BucketsApiFp(configuration).deleteBucket(bucket_name, options)(fetch, basePath);
        },
        /**
         * API route to delete a given file or folder  Returns:     :Response: API response
         * @summary Delete File
         * @param {string} bucket_name
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(bucket_name, filename, options) {
            return BucketsApiFp(configuration).deleteFile(bucket_name, filename, options)(fetch, basePath);
        },
        /**
         * API route to list contents of bucket  Returns:     :Response: API response
         * @summary Get Bucket Contents
         * @param {string} bucket_name
         * @param {string} prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBucketContents(bucket_name, prefix, options) {
            return BucketsApiFp(configuration).getBucketContents(bucket_name, prefix, options)(fetch, basePath);
        },
        /**
         * API route to list all data buckets  Returns:     :Response: API response
         * @summary Get Buckets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuckets(options) {
            return BucketsApiFp(configuration).getBuckets(options)(fetch, basePath);
        },
        /**
         * API route to get presigned get URL  Returns:     :Response: API response
         * @summary Get Download Url
         * @param {string} bucket_name
         * @param {string} prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl(bucket_name, prefix, options) {
            return BucketsApiFp(configuration).getDownloadUrl(bucket_name, prefix, options)(fetch, basePath);
        },
        /**
         * API route to get an upload URL  Returns:     :Response: API response
         * @summary Get Upload Url
         * @param {string} bucket_name
         * @param {string} filename
         * @param {string} [content_type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadUrl(bucket_name, filename, content_type, options) {
            return BucketsApiFp(configuration).getUploadUrl(bucket_name, filename, content_type, options)(fetch, basePath);
        },
    };
};
/**
 * BucketsApi - object-oriented interface
 * @export
 * @class BucketsApi
 * @extends {BaseAPI}
 */
export class BucketsApi extends BaseAPI {
    /**
     * API route to create a new bucket  Returns:     :Response: API response
     * @summary Create Bucket
     * @param {CreateBucketData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketsApi
     */
    createBucket(body, options) {
        return BucketsApiFp(this.configuration).createBucket(body, options)(this.fetch, this.basePath);
    }
    /**
     * API route to delete a given data bucket  Returns:     :Response: API response
     * @summary Delete Bucket
     * @param {string} bucket_name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketsApi
     */
    deleteBucket(bucket_name, options) {
        return BucketsApiFp(this.configuration).deleteBucket(bucket_name, options)(this.fetch, this.basePath);
    }
    /**
     * API route to delete a given file or folder  Returns:     :Response: API response
     * @summary Delete File
     * @param {string} bucket_name
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketsApi
     */
    deleteFile(bucket_name, filename, options) {
        return BucketsApiFp(this.configuration).deleteFile(bucket_name, filename, options)(this.fetch, this.basePath);
    }
    /**
     * API route to list contents of bucket  Returns:     :Response: API response
     * @summary Get Bucket Contents
     * @param {string} bucket_name
     * @param {string} prefix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketsApi
     */
    getBucketContents(bucket_name, prefix, options) {
        return BucketsApiFp(this.configuration).getBucketContents(bucket_name, prefix, options)(this.fetch, this.basePath);
    }
    /**
     * API route to list all data buckets  Returns:     :Response: API response
     * @summary Get Buckets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketsApi
     */
    getBuckets(options) {
        return BucketsApiFp(this.configuration).getBuckets(options)(this.fetch, this.basePath);
    }
    /**
     * API route to get presigned get URL  Returns:     :Response: API response
     * @summary Get Download Url
     * @param {string} bucket_name
     * @param {string} prefix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketsApi
     */
    getDownloadUrl(bucket_name, prefix, options) {
        return BucketsApiFp(this.configuration).getDownloadUrl(bucket_name, prefix, options)(this.fetch, this.basePath);
    }
    /**
     * API route to get an upload URL  Returns:     :Response: API response
     * @summary Get Upload Url
     * @param {string} bucket_name
     * @param {string} filename
     * @param {string} [content_type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketsApi
     */
    getUploadUrl(bucket_name, filename, content_type, options) {
        return BucketsApiFp(this.configuration).getUploadUrl(bucket_name, filename, content_type, options)(this.fetch, this.basePath);
    }
}
/**
 * BudgetsApi - fetch parameter creator
 * @export
 */
export const BudgetsApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get Budget
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudget(options = {}) {
            const localVarPath = `/budgets/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Set Budget
         * @param {number} amount
         * @param {string} start_date
         * @param {string} end_date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBudget(amount, start_date, end_date, options = {}) {
            // verify required parameter 'amount' is not null or undefined
            if (amount === null || amount === undefined) {
                throw new RequiredError('amount', 'Required parameter amount was null or undefined when calling setBudget.');
            }
            // verify required parameter 'start_date' is not null or undefined
            if (start_date === null || start_date === undefined) {
                throw new RequiredError('start_date', 'Required parameter start_date was null or undefined when calling setBudget.');
            }
            // verify required parameter 'end_date' is not null or undefined
            if (end_date === null || end_date === undefined) {
                throw new RequiredError('end_date', 'Required parameter end_date was null or undefined when calling setBudget.');
            }
            const localVarPath = `/budgets/set`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }
            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }
            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BudgetsApi - functional programming interface
 * @export
 */
export const BudgetsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get Budget
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudget(options) {
            const localVarFetchArgs = BudgetsApiFetchParamCreator(configuration).getBudget(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Set Budget
         * @param {number} amount
         * @param {string} start_date
         * @param {string} end_date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBudget(amount, start_date, end_date, options) {
            const localVarFetchArgs = BudgetsApiFetchParamCreator(configuration).setBudget(amount, start_date, end_date, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * BudgetsApi - factory interface
 * @export
 */
export const BudgetsApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Get Budget
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudget(options) {
            return BudgetsApiFp(configuration).getBudget(options)(fetch, basePath);
        },
        /**
         *
         * @summary Set Budget
         * @param {number} amount
         * @param {string} start_date
         * @param {string} end_date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBudget(amount, start_date, end_date, options) {
            return BudgetsApiFp(configuration).setBudget(amount, start_date, end_date, options)(fetch, basePath);
        },
    };
};
/**
 * BudgetsApi - object-oriented interface
 * @export
 * @class BudgetsApi
 * @extends {BaseAPI}
 */
export class BudgetsApi extends BaseAPI {
    /**
     *
     * @summary Get Budget
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetsApi
     */
    getBudget(options) {
        return BudgetsApiFp(this.configuration).getBudget(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Set Budget
     * @param {number} amount
     * @param {string} start_date
     * @param {string} end_date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetsApi
     */
    setBudget(amount, start_date, end_date, options) {
        return BudgetsApiFp(this.configuration).setBudget(amount, start_date, end_date, options)(this.fetch, this.basePath);
    }
}
/**
 * CostsApi - fetch parameter creator
 * @export
 */
export const CostsApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get Bare Metal Cost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBareMetalCost(options = {}) {
            const localVarPath = `/costs/bare-metal`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Combined Cost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCombinedCost(options = {}) {
            const localVarPath = `/costs/combined`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get S3 Cost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3Cost(options = {}) {
            const localVarPath = `/costs/s3`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * CostsApi - functional programming interface
 * @export
 */
export const CostsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get Bare Metal Cost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBareMetalCost(options) {
            const localVarFetchArgs = CostsApiFetchParamCreator(configuration).getBareMetalCost(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get Combined Cost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCombinedCost(options) {
            const localVarFetchArgs = CostsApiFetchParamCreator(configuration).getCombinedCost(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get S3 Cost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3Cost(options) {
            const localVarFetchArgs = CostsApiFetchParamCreator(configuration).getS3Cost(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * CostsApi - factory interface
 * @export
 */
export const CostsApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Get Bare Metal Cost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBareMetalCost(options) {
            return CostsApiFp(configuration).getBareMetalCost(options)(fetch, basePath);
        },
        /**
         *
         * @summary Get Combined Cost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCombinedCost(options) {
            return CostsApiFp(configuration).getCombinedCost(options)(fetch, basePath);
        },
        /**
         *
         * @summary Get S3 Cost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3Cost(options) {
            return CostsApiFp(configuration).getS3Cost(options)(fetch, basePath);
        },
    };
};
/**
 * CostsApi - object-oriented interface
 * @export
 * @class CostsApi
 * @extends {BaseAPI}
 */
export class CostsApi extends BaseAPI {
    /**
     *
     * @summary Get Bare Metal Cost
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostsApi
     */
    getBareMetalCost(options) {
        return CostsApiFp(this.configuration).getBareMetalCost(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Get Combined Cost
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostsApi
     */
    getCombinedCost(options) {
        return CostsApiFp(this.configuration).getCombinedCost(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Get S3 Cost
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostsApi
     */
    getS3Cost(options) {
        return CostsApiFp(this.configuration).getS3Cost(options)(this.fetch, this.basePath);
    }
}
/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (configuration) {
    return {
        /**
         * API route to provide a simple status  Returns:     :Response: A generic API response json object
         * @summary Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        status(options = {}) {
            const localVarPath = `/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration) {
    return {
        /**
         * API route to provide a simple status  Returns:     :Response: A generic API response json object
         * @summary Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        status(options) {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).status(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * API route to provide a simple status  Returns:     :Response: A generic API response json object
         * @summary Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        status(options) {
            return DefaultApiFp(configuration).status(options)(fetch, basePath);
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * API route to provide a simple status  Returns:     :Response: A generic API response json object
     * @summary Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    status(options) {
        return DefaultApiFp(this.configuration).status(options)(this.fetch, this.basePath);
    }
}
/**
 * EnvironmentsApi - fetch parameter creator
 * @export
 */
export const EnvironmentsApiFetchParamCreator = function (configuration) {
    return {
        /**
         * API route to deploy the container  Returns:     :Response: API response
         * @summary Create Container
         * @param {Data} body
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContainer(body, environment_id, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createContainer.');
            }
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling createContainer.');
            }
            const localVarPath = `/environments/{environment_id}/containers`.replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = 'Data' !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] ===
                    'application/json';
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || '';
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to create a new environment  Returns:     :dict: Environment data of the new stack
         * @summary Create Environment
         * @param {CreateEnvironmentData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironment(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createEnvironment.');
            }
            const localVarPath = `/environments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = 'CreateEnvironmentData' !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] ===
                    'application/json';
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || '';
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to delete a container  Returns:     :Response: API response
         * @summary Delete Container
         * @param {string} environment_id
         * @param {string} container_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContainer(environment_id, container_id, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling deleteContainer.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling deleteContainer.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to delete an environment  Returns:     :Response: API response
         * @summary Delete Environment
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironment(environment_id, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling deleteEnvironment.');
            }
            const localVarPath = `/environments/{environment_id}`.replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to delete a virtual machine snapsho  Returns:     :Response: API response
         * @summary Delete Virtual Machine Snapshot
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {string} snapshot_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling deleteVirtualMachineSnapshot.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling deleteVirtualMachineSnapshot.');
            }
            // verify required parameter 'virtual_machine' is not null or undefined
            if (virtual_machine === null || virtual_machine === undefined) {
                throw new RequiredError('virtual_machine', 'Required parameter virtual_machine was null or undefined when calling deleteVirtualMachineSnapshot.');
            }
            // verify required parameter 'snapshot_id' is not null or undefined
            if (snapshot_id === null || snapshot_id === undefined) {
                throw new RequiredError('snapshot_id', 'Required parameter snapshot_id was null or undefined when calling deleteVirtualMachineSnapshot.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}/{virtual_machine}/{snapshot_name}`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)))
                .replace(`{${'virtual_machine'}}`, encodeURIComponent(String(virtual_machine)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (snapshot_id !== undefined) {
                localVarQueryParameter['snapshot_id'] = snapshot_id;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to get the container  Returns:     :Response: API response
         * @summary Get Container
         * @param {string} environment_id
         * @param {string} container_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainer(environment_id, container_id, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling getContainer.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling getContainer.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to list container types  Returns:     :Response: API response
         * @summary Get Container Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerTypes(options = {}) {
            const localVarPath = `/environments/container_types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to get a single environment  Returns:     :Response: API response
         * @summary Get Environment
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironment(environment_id, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling getEnvironment.');
            }
            const localVarPath = `/environments/{environment_id}`.replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Websocket Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsocketUrl(options = {}) {
            const localVarPath = `/environments/websocket-url`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to list all containers  Returns:     :Response: API response
         * @summary List Containers
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContainers(environment_id, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling listContainers.');
            }
            const localVarPath = `/environments/{environment_id}/containers`.replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to list all environments  Returns:     :Response: API response
         * @summary List Environments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnvironments(options = {}) {
            const localVarPath = `/environments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to list all VMs  Returns:     :Response: API response
         * @summary List Vms
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVms(environment_id, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling listVms.');
            }
            const localVarPath = `/environments/{environment_id}/vms`.replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to restore a virtual machine snapshot  Returns:     :Response: API response
         * @summary Restore Virtual Machine Snapshot
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {string} snapshot_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling restoreVirtualMachineSnapshot.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling restoreVirtualMachineSnapshot.');
            }
            // verify required parameter 'virtual_machine' is not null or undefined
            if (virtual_machine === null || virtual_machine === undefined) {
                throw new RequiredError('virtual_machine', 'Required parameter virtual_machine was null or undefined when calling restoreVirtualMachineSnapshot.');
            }
            // verify required parameter 'snapshot_id' is not null or undefined
            if (snapshot_id === null || snapshot_id === undefined) {
                throw new RequiredError('snapshot_id', 'Required parameter snapshot_id was null or undefined when calling restoreVirtualMachineSnapshot.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}/restore/{virtual_machine}/{snapshot_name}`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)))
                .replace(`{${'virtual_machine'}}`, encodeURIComponent(String(virtual_machine)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (snapshot_id !== undefined) {
                localVarQueryParameter['snapshot_id'] = snapshot_id;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to resume virtual machine state  Returns:     :Response:  API response
         * @summary Resume Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeVirtualMachine(environment_id, container_id, virtual_machine, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling resumeVirtualMachine.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling resumeVirtualMachine.');
            }
            // verify required parameter 'virtual_machine' is not null or undefined
            if (virtual_machine === null || virtual_machine === undefined) {
                throw new RequiredError('virtual_machine', 'Required parameter virtual_machine was null or undefined when calling resumeVirtualMachine.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}/resume/{virtual_machine}`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)))
                .replace(`{${'virtual_machine'}}`, encodeURIComponent(String(virtual_machine)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to create a snapshot for a virtual machine  Returns:     :Response: API response
         * @summary Snapshot Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {string} snapshot_name
         * @param {string} snapshot_description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotVirtualMachine(environment_id, container_id, virtual_machine, snapshot_name, snapshot_description, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling snapshotVirtualMachine.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling snapshotVirtualMachine.');
            }
            // verify required parameter 'virtual_machine' is not null or undefined
            if (virtual_machine === null || virtual_machine === undefined) {
                throw new RequiredError('virtual_machine', 'Required parameter virtual_machine was null or undefined when calling snapshotVirtualMachine.');
            }
            // verify required parameter 'snapshot_name' is not null or undefined
            if (snapshot_name === null || snapshot_name === undefined) {
                throw new RequiredError('snapshot_name', 'Required parameter snapshot_name was null or undefined when calling snapshotVirtualMachine.');
            }
            // verify required parameter 'snapshot_description' is not null or undefined
            if (snapshot_description === null ||
                snapshot_description === undefined) {
                throw new RequiredError('snapshot_description', 'Required parameter snapshot_description was null or undefined when calling snapshotVirtualMachine.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}/snapshot/{virtual_machine}`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)))
                .replace(`{${'virtual_machine'}}`, encodeURIComponent(String(virtual_machine)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (snapshot_name !== undefined) {
                localVarQueryParameter['snapshot_name'] = snapshot_name;
            }
            if (snapshot_description !== undefined) {
                localVarQueryParameter['snapshot_description'] =
                    snapshot_description;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Sse Endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sseEndpoint(options = {}) {
            const localVarPath = `/environments/sse`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to start a terminal session  Returns:     :Response: API response
         * @summary Start Terminal Session
         * @param {string} environment_id
         * @param {string} container_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTerminalSession(environment_id, container_id, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling startTerminalSession.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling startTerminalSession.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}/terminal-session`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to start a VM  Returns:     :Response: API Response
         * @summary Start Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startVirtualMachine(environment_id, container_id, virtual_machine, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling startVirtualMachine.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling startVirtualMachine.');
            }
            // verify required parameter 'virtual_machine' is not null or undefined
            if (virtual_machine === null || virtual_machine === undefined) {
                throw new RequiredError('virtual_machine', 'Required parameter virtual_machine was null or undefined when calling startVirtualMachine.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}/start/{virtual_machine}`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)))
                .replace(`{${'virtual_machine'}}`, encodeURIComponent(String(virtual_machine)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to start the vm terminal session  Returns:     :Response: API response
         * @summary Start Virtual Machine Terminal Session
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {boolean} [tty]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startVirtualMachineTerminalSession(environment_id, container_id, virtual_machine, tty, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling startVirtualMachineTerminalSession.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling startVirtualMachineTerminalSession.');
            }
            // verify required parameter 'virtual_machine' is not null or undefined
            if (virtual_machine === null || virtual_machine === undefined) {
                throw new RequiredError('virtual_machine', 'Required parameter virtual_machine was null or undefined when calling startVirtualMachineTerminalSession.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}/vms/{virtual_machine}/terminal-session`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)))
                .replace(`{${'virtual_machine'}}`, encodeURIComponent(String(virtual_machine)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tty !== undefined) {
                localVarQueryParameter['tty'] = tty;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to stop a virtual machine  Returns:     :Response: API response
         * @summary Stop Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopVirtualMachine(environment_id, container_id, virtual_machine, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling stopVirtualMachine.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling stopVirtualMachine.');
            }
            // verify required parameter 'virtual_machine' is not null or undefined
            if (virtual_machine === null || virtual_machine === undefined) {
                throw new RequiredError('virtual_machine', 'Required parameter virtual_machine was null or undefined when calling stopVirtualMachine.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}/stop/{virtual_machine}`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)))
                .replace(`{${'virtual_machine'}}`, encodeURIComponent(String(virtual_machine)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to suspend a virtual machine  Returns:     :Response: API response
         * @summary Suspend Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendVirtualMachine(environment_id, container_id, virtual_machine, options = {}) {
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling suspendVirtualMachine.');
            }
            // verify required parameter 'container_id' is not null or undefined
            if (container_id === null || container_id === undefined) {
                throw new RequiredError('container_id', 'Required parameter container_id was null or undefined when calling suspendVirtualMachine.');
            }
            // verify required parameter 'virtual_machine' is not null or undefined
            if (virtual_machine === null || virtual_machine === undefined) {
                throw new RequiredError('virtual_machine', 'Required parameter virtual_machine was null or undefined when calling suspendVirtualMachine.');
            }
            const localVarPath = `/environments/{environment_id}/containers/{container_id}/suspend/{virtual_machine}`
                .replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)))
                .replace(`{${'container_id'}}`, encodeURIComponent(String(container_id)))
                .replace(`{${'virtual_machine'}}`, encodeURIComponent(String(virtual_machine)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * EnvironmentsApi - functional programming interface
 * @export
 */
export const EnvironmentsApiFp = function (configuration) {
    return {
        /**
         * API route to deploy the container  Returns:     :Response: API response
         * @summary Create Container
         * @param {Data} body
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContainer(body, environment_id, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).createContainer(body, environment_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to create a new environment  Returns:     :dict: Environment data of the new stack
         * @summary Create Environment
         * @param {CreateEnvironmentData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironment(body, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).createEnvironment(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to delete a container  Returns:     :Response: API response
         * @summary Delete Container
         * @param {string} environment_id
         * @param {string} container_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContainer(environment_id, container_id, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).deleteContainer(environment_id, container_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to delete an environment  Returns:     :Response: API response
         * @summary Delete Environment
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironment(environment_id, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).deleteEnvironment(environment_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to delete a virtual machine snapsho  Returns:     :Response: API response
         * @summary Delete Virtual Machine Snapshot
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {string} snapshot_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).deleteVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to get the container  Returns:     :Response: API response
         * @summary Get Container
         * @param {string} environment_id
         * @param {string} container_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainer(environment_id, container_id, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).getContainer(environment_id, container_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to list container types  Returns:     :Response: API response
         * @summary Get Container Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerTypes(options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).getContainerTypes(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to get a single environment  Returns:     :Response: API response
         * @summary Get Environment
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironment(environment_id, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).getEnvironment(environment_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get Websocket Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsocketUrl(options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).getWebsocketUrl(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to list all containers  Returns:     :Response: API response
         * @summary List Containers
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContainers(environment_id, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).listContainers(environment_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to list all environments  Returns:     :Response: API response
         * @summary List Environments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnvironments(options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).listEnvironments(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to list all VMs  Returns:     :Response: API response
         * @summary List Vms
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVms(environment_id, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).listVms(environment_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to restore a virtual machine snapshot  Returns:     :Response: API response
         * @summary Restore Virtual Machine Snapshot
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {string} snapshot_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).restoreVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to resume virtual machine state  Returns:     :Response:  API response
         * @summary Resume Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeVirtualMachine(environment_id, container_id, virtual_machine, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).resumeVirtualMachine(environment_id, container_id, virtual_machine, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to create a snapshot for a virtual machine  Returns:     :Response: API response
         * @summary Snapshot Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {string} snapshot_name
         * @param {string} snapshot_description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotVirtualMachine(environment_id, container_id, virtual_machine, snapshot_name, snapshot_description, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).snapshotVirtualMachine(environment_id, container_id, virtual_machine, snapshot_name, snapshot_description, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Sse Endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sseEndpoint(options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).sseEndpoint(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to start a terminal session  Returns:     :Response: API response
         * @summary Start Terminal Session
         * @param {string} environment_id
         * @param {string} container_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTerminalSession(environment_id, container_id, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).startTerminalSession(environment_id, container_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to start a VM  Returns:     :Response: API Response
         * @summary Start Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startVirtualMachine(environment_id, container_id, virtual_machine, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).startVirtualMachine(environment_id, container_id, virtual_machine, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to start the vm terminal session  Returns:     :Response: API response
         * @summary Start Virtual Machine Terminal Session
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {boolean} [tty]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startVirtualMachineTerminalSession(environment_id, container_id, virtual_machine, tty, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).startVirtualMachineTerminalSession(environment_id, container_id, virtual_machine, tty, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to stop a virtual machine  Returns:     :Response: API response
         * @summary Stop Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopVirtualMachine(environment_id, container_id, virtual_machine, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).stopVirtualMachine(environment_id, container_id, virtual_machine, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to suspend a virtual machine  Returns:     :Response: API response
         * @summary Suspend Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendVirtualMachine(environment_id, container_id, virtual_machine, options) {
            const localVarFetchArgs = EnvironmentsApiFetchParamCreator(configuration).suspendVirtualMachine(environment_id, container_id, virtual_machine, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * EnvironmentsApi - factory interface
 * @export
 */
export const EnvironmentsApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * API route to deploy the container  Returns:     :Response: API response
         * @summary Create Container
         * @param {Data} body
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContainer(body, environment_id, options) {
            return EnvironmentsApiFp(configuration).createContainer(body, environment_id, options)(fetch, basePath);
        },
        /**
         * API route to create a new environment  Returns:     :dict: Environment data of the new stack
         * @summary Create Environment
         * @param {CreateEnvironmentData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironment(body, options) {
            return EnvironmentsApiFp(configuration).createEnvironment(body, options)(fetch, basePath);
        },
        /**
         * API route to delete a container  Returns:     :Response: API response
         * @summary Delete Container
         * @param {string} environment_id
         * @param {string} container_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContainer(environment_id, container_id, options) {
            return EnvironmentsApiFp(configuration).deleteContainer(environment_id, container_id, options)(fetch, basePath);
        },
        /**
         * API route to delete an environment  Returns:     :Response: API response
         * @summary Delete Environment
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironment(environment_id, options) {
            return EnvironmentsApiFp(configuration).deleteEnvironment(environment_id, options)(fetch, basePath);
        },
        /**
         * API route to delete a virtual machine snapsho  Returns:     :Response: API response
         * @summary Delete Virtual Machine Snapshot
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {string} snapshot_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options) {
            return EnvironmentsApiFp(configuration).deleteVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options)(fetch, basePath);
        },
        /**
         * API route to get the container  Returns:     :Response: API response
         * @summary Get Container
         * @param {string} environment_id
         * @param {string} container_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainer(environment_id, container_id, options) {
            return EnvironmentsApiFp(configuration).getContainer(environment_id, container_id, options)(fetch, basePath);
        },
        /**
         * API route to list container types  Returns:     :Response: API response
         * @summary Get Container Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerTypes(options) {
            return EnvironmentsApiFp(configuration).getContainerTypes(options)(fetch, basePath);
        },
        /**
         * API route to get a single environment  Returns:     :Response: API response
         * @summary Get Environment
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironment(environment_id, options) {
            return EnvironmentsApiFp(configuration).getEnvironment(environment_id, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get Websocket Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsocketUrl(options) {
            return EnvironmentsApiFp(configuration).getWebsocketUrl(options)(fetch, basePath);
        },
        /**
         * API route to list all containers  Returns:     :Response: API response
         * @summary List Containers
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContainers(environment_id, options) {
            return EnvironmentsApiFp(configuration).listContainers(environment_id, options)(fetch, basePath);
        },
        /**
         * API route to list all environments  Returns:     :Response: API response
         * @summary List Environments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnvironments(options) {
            return EnvironmentsApiFp(configuration).listEnvironments(options)(fetch, basePath);
        },
        /**
         * API route to list all VMs  Returns:     :Response: API response
         * @summary List Vms
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVms(environment_id, options) {
            return EnvironmentsApiFp(configuration).listVms(environment_id, options)(fetch, basePath);
        },
        /**
         * API route to restore a virtual machine snapshot  Returns:     :Response: API response
         * @summary Restore Virtual Machine Snapshot
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {string} snapshot_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options) {
            return EnvironmentsApiFp(configuration).restoreVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options)(fetch, basePath);
        },
        /**
         * API route to resume virtual machine state  Returns:     :Response:  API response
         * @summary Resume Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeVirtualMachine(environment_id, container_id, virtual_machine, options) {
            return EnvironmentsApiFp(configuration).resumeVirtualMachine(environment_id, container_id, virtual_machine, options)(fetch, basePath);
        },
        /**
         * API route to create a snapshot for a virtual machine  Returns:     :Response: API response
         * @summary Snapshot Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {string} snapshot_name
         * @param {string} snapshot_description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotVirtualMachine(environment_id, container_id, virtual_machine, snapshot_name, snapshot_description, options) {
            return EnvironmentsApiFp(configuration).snapshotVirtualMachine(environment_id, container_id, virtual_machine, snapshot_name, snapshot_description, options)(fetch, basePath);
        },
        /**
         *
         * @summary Sse Endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sseEndpoint(options) {
            return EnvironmentsApiFp(configuration).sseEndpoint(options)(fetch, basePath);
        },
        /**
         * API route to start a terminal session  Returns:     :Response: API response
         * @summary Start Terminal Session
         * @param {string} environment_id
         * @param {string} container_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTerminalSession(environment_id, container_id, options) {
            return EnvironmentsApiFp(configuration).startTerminalSession(environment_id, container_id, options)(fetch, basePath);
        },
        /**
         * API route to start a VM  Returns:     :Response: API Response
         * @summary Start Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startVirtualMachine(environment_id, container_id, virtual_machine, options) {
            return EnvironmentsApiFp(configuration).startVirtualMachine(environment_id, container_id, virtual_machine, options)(fetch, basePath);
        },
        /**
         * API route to start the vm terminal session  Returns:     :Response: API response
         * @summary Start Virtual Machine Terminal Session
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {boolean} [tty]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startVirtualMachineTerminalSession(environment_id, container_id, virtual_machine, tty, options) {
            return EnvironmentsApiFp(configuration).startVirtualMachineTerminalSession(environment_id, container_id, virtual_machine, tty, options)(fetch, basePath);
        },
        /**
         * API route to stop a virtual machine  Returns:     :Response: API response
         * @summary Stop Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopVirtualMachine(environment_id, container_id, virtual_machine, options) {
            return EnvironmentsApiFp(configuration).stopVirtualMachine(environment_id, container_id, virtual_machine, options)(fetch, basePath);
        },
        /**
         * API route to suspend a virtual machine  Returns:     :Response: API response
         * @summary Suspend Virtual Machine
         * @param {string} environment_id
         * @param {string} container_id
         * @param {string} virtual_machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendVirtualMachine(environment_id, container_id, virtual_machine, options) {
            return EnvironmentsApiFp(configuration).suspendVirtualMachine(environment_id, container_id, virtual_machine, options)(fetch, basePath);
        },
    };
};
/**
 * EnvironmentsApi - object-oriented interface
 * @export
 * @class EnvironmentsApi
 * @extends {BaseAPI}
 */
export class EnvironmentsApi extends BaseAPI {
    /**
     * API route to deploy the container  Returns:     :Response: API response
     * @summary Create Container
     * @param {Data} body
     * @param {string} environment_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    createContainer(body, environment_id, options) {
        return EnvironmentsApiFp(this.configuration).createContainer(body, environment_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to create a new environment  Returns:     :dict: Environment data of the new stack
     * @summary Create Environment
     * @param {CreateEnvironmentData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    createEnvironment(body, options) {
        return EnvironmentsApiFp(this.configuration).createEnvironment(body, options)(this.fetch, this.basePath);
    }
    /**
     * API route to delete a container  Returns:     :Response: API response
     * @summary Delete Container
     * @param {string} environment_id
     * @param {string} container_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    deleteContainer(environment_id, container_id, options) {
        return EnvironmentsApiFp(this.configuration).deleteContainer(environment_id, container_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to delete an environment  Returns:     :Response: API response
     * @summary Delete Environment
     * @param {string} environment_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    deleteEnvironment(environment_id, options) {
        return EnvironmentsApiFp(this.configuration).deleteEnvironment(environment_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to delete a virtual machine snapsho  Returns:     :Response: API response
     * @summary Delete Virtual Machine Snapshot
     * @param {string} environment_id
     * @param {string} container_id
     * @param {string} virtual_machine
     * @param {string} snapshot_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    deleteVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options) {
        return EnvironmentsApiFp(this.configuration).deleteVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to get the container  Returns:     :Response: API response
     * @summary Get Container
     * @param {string} environment_id
     * @param {string} container_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    getContainer(environment_id, container_id, options) {
        return EnvironmentsApiFp(this.configuration).getContainer(environment_id, container_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to list container types  Returns:     :Response: API response
     * @summary Get Container Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    getContainerTypes(options) {
        return EnvironmentsApiFp(this.configuration).getContainerTypes(options)(this.fetch, this.basePath);
    }
    /**
     * API route to get a single environment  Returns:     :Response: API response
     * @summary Get Environment
     * @param {string} environment_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    getEnvironment(environment_id, options) {
        return EnvironmentsApiFp(this.configuration).getEnvironment(environment_id, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Get Websocket Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    getWebsocketUrl(options) {
        return EnvironmentsApiFp(this.configuration).getWebsocketUrl(options)(this.fetch, this.basePath);
    }
    /**
     * API route to list all containers  Returns:     :Response: API response
     * @summary List Containers
     * @param {string} environment_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    listContainers(environment_id, options) {
        return EnvironmentsApiFp(this.configuration).listContainers(environment_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to list all environments  Returns:     :Response: API response
     * @summary List Environments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    listEnvironments(options) {
        return EnvironmentsApiFp(this.configuration).listEnvironments(options)(this.fetch, this.basePath);
    }
    /**
     * API route to list all VMs  Returns:     :Response: API response
     * @summary List Vms
     * @param {string} environment_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    listVms(environment_id, options) {
        return EnvironmentsApiFp(this.configuration).listVms(environment_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to restore a virtual machine snapshot  Returns:     :Response: API response
     * @summary Restore Virtual Machine Snapshot
     * @param {string} environment_id
     * @param {string} container_id
     * @param {string} virtual_machine
     * @param {string} snapshot_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    restoreVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options) {
        return EnvironmentsApiFp(this.configuration).restoreVirtualMachineSnapshot(environment_id, container_id, virtual_machine, snapshot_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to resume virtual machine state  Returns:     :Response:  API response
     * @summary Resume Virtual Machine
     * @param {string} environment_id
     * @param {string} container_id
     * @param {string} virtual_machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    resumeVirtualMachine(environment_id, container_id, virtual_machine, options) {
        return EnvironmentsApiFp(this.configuration).resumeVirtualMachine(environment_id, container_id, virtual_machine, options)(this.fetch, this.basePath);
    }
    /**
     * API route to create a snapshot for a virtual machine  Returns:     :Response: API response
     * @summary Snapshot Virtual Machine
     * @param {string} environment_id
     * @param {string} container_id
     * @param {string} virtual_machine
     * @param {string} snapshot_name
     * @param {string} snapshot_description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    snapshotVirtualMachine(environment_id, container_id, virtual_machine, snapshot_name, snapshot_description, options) {
        return EnvironmentsApiFp(this.configuration).snapshotVirtualMachine(environment_id, container_id, virtual_machine, snapshot_name, snapshot_description, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Sse Endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    sseEndpoint(options) {
        return EnvironmentsApiFp(this.configuration).sseEndpoint(options)(this.fetch, this.basePath);
    }
    /**
     * API route to start a terminal session  Returns:     :Response: API response
     * @summary Start Terminal Session
     * @param {string} environment_id
     * @param {string} container_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    startTerminalSession(environment_id, container_id, options) {
        return EnvironmentsApiFp(this.configuration).startTerminalSession(environment_id, container_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to start a VM  Returns:     :Response: API Response
     * @summary Start Virtual Machine
     * @param {string} environment_id
     * @param {string} container_id
     * @param {string} virtual_machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    startVirtualMachine(environment_id, container_id, virtual_machine, options) {
        return EnvironmentsApiFp(this.configuration).startVirtualMachine(environment_id, container_id, virtual_machine, options)(this.fetch, this.basePath);
    }
    /**
     * API route to start the vm terminal session  Returns:     :Response: API response
     * @summary Start Virtual Machine Terminal Session
     * @param {string} environment_id
     * @param {string} container_id
     * @param {string} virtual_machine
     * @param {boolean} [tty]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    startVirtualMachineTerminalSession(environment_id, container_id, virtual_machine, tty, options) {
        return EnvironmentsApiFp(this.configuration).startVirtualMachineTerminalSession(environment_id, container_id, virtual_machine, tty, options)(this.fetch, this.basePath);
    }
    /**
     * API route to stop a virtual machine  Returns:     :Response: API response
     * @summary Stop Virtual Machine
     * @param {string} environment_id
     * @param {string} container_id
     * @param {string} virtual_machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    stopVirtualMachine(environment_id, container_id, virtual_machine, options) {
        return EnvironmentsApiFp(this.configuration).stopVirtualMachine(environment_id, container_id, virtual_machine, options)(this.fetch, this.basePath);
    }
    /**
     * API route to suspend a virtual machine  Returns:     :Response: API response
     * @summary Suspend Virtual Machine
     * @param {string} environment_id
     * @param {string} container_id
     * @param {string} virtual_machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentsApi
     */
    suspendVirtualMachine(environment_id, container_id, virtual_machine, options) {
        return EnvironmentsApiFp(this.configuration).suspendVirtualMachine(environment_id, container_id, virtual_machine, options)(this.fetch, this.basePath);
    }
}
/**
 * NotebooksApi - fetch parameter creator
 * @export
 */
export const NotebooksApiFetchParamCreator = function (configuration) {
    return {
        /**
         * API route to create a notebook  Returns:     :Response: API response
         * @summary Create Notebook
         * @param {CreateNotebookData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotebook(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createNotebook.');
            }
            const localVarPath = `/notebooks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = 'CreateNotebookData' !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] ===
                    'application/json';
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || '';
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to delete a notebook  Returns:     :Response: API response
         * @summary Delete Notebook
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotebook(notebook_id, options = {}) {
            // verify required parameter 'notebook_id' is not null or undefined
            if (notebook_id === null || notebook_id === undefined) {
                throw new RequiredError('notebook_id', 'Required parameter notebook_id was null or undefined when calling deleteNotebook.');
            }
            const localVarPath = `/notebooks{notebook_id}`.replace(`{${'notebook_id'}}`, encodeURIComponent(String(notebook_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to get notebooks  Returns:     :Response: API response
         * @summary Get Notebooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotebooks(options = {}) {
            const localVarPath = `/notebooks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to get a presigned URL for the notebook  Returns:     :Response: API response
         * @summary Get Presigned Url
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrl(notebook_id, options = {}) {
            // verify required parameter 'notebook_id' is not null or undefined
            if (notebook_id === null || notebook_id === undefined) {
                throw new RequiredError('notebook_id', 'Required parameter notebook_id was null or undefined when calling getPresignedUrl.');
            }
            const localVarPath = `/notebooks{notebook_id}/presigned-url`.replace(`{${'notebook_id'}}`, encodeURIComponent(String(notebook_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to start a notebook  Returns:     :Response: API response
         * @summary Start Notebook
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startNotebook(notebook_id, options = {}) {
            // verify required parameter 'notebook_id' is not null or undefined
            if (notebook_id === null || notebook_id === undefined) {
                throw new RequiredError('notebook_id', 'Required parameter notebook_id was null or undefined when calling startNotebook.');
            }
            const localVarPath = `/notebooks{notebook_id}/start`.replace(`{${'notebook_id'}}`, encodeURIComponent(String(notebook_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to stop notebooks  Returns:     :Response: API response
         * @summary Stop Notebook
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopNotebook(notebook_id, options = {}) {
            // verify required parameter 'notebook_id' is not null or undefined
            if (notebook_id === null || notebook_id === undefined) {
                throw new RequiredError('notebook_id', 'Required parameter notebook_id was null or undefined when calling stopNotebook.');
            }
            const localVarPath = `/notebooks{notebook_id}/stop`.replace(`{${'notebook_id'}}`, encodeURIComponent(String(notebook_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * NotebooksApi - functional programming interface
 * @export
 */
export const NotebooksApiFp = function (configuration) {
    return {
        /**
         * API route to create a notebook  Returns:     :Response: API response
         * @summary Create Notebook
         * @param {CreateNotebookData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotebook(body, options) {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).createNotebook(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to delete a notebook  Returns:     :Response: API response
         * @summary Delete Notebook
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotebook(notebook_id, options) {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).deleteNotebook(notebook_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to get notebooks  Returns:     :Response: API response
         * @summary Get Notebooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotebooks(options) {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).getNotebooks(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to get a presigned URL for the notebook  Returns:     :Response: API response
         * @summary Get Presigned Url
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrl(notebook_id, options) {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).getPresignedUrl(notebook_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to start a notebook  Returns:     :Response: API response
         * @summary Start Notebook
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startNotebook(notebook_id, options) {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).startNotebook(notebook_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to stop notebooks  Returns:     :Response: API response
         * @summary Stop Notebook
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopNotebook(notebook_id, options) {
            const localVarFetchArgs = NotebooksApiFetchParamCreator(configuration).stopNotebook(notebook_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * NotebooksApi - factory interface
 * @export
 */
export const NotebooksApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * API route to create a notebook  Returns:     :Response: API response
         * @summary Create Notebook
         * @param {CreateNotebookData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotebook(body, options) {
            return NotebooksApiFp(configuration).createNotebook(body, options)(fetch, basePath);
        },
        /**
         * API route to delete a notebook  Returns:     :Response: API response
         * @summary Delete Notebook
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotebook(notebook_id, options) {
            return NotebooksApiFp(configuration).deleteNotebook(notebook_id, options)(fetch, basePath);
        },
        /**
         * API route to get notebooks  Returns:     :Response: API response
         * @summary Get Notebooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotebooks(options) {
            return NotebooksApiFp(configuration).getNotebooks(options)(fetch, basePath);
        },
        /**
         * API route to get a presigned URL for the notebook  Returns:     :Response: API response
         * @summary Get Presigned Url
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrl(notebook_id, options) {
            return NotebooksApiFp(configuration).getPresignedUrl(notebook_id, options)(fetch, basePath);
        },
        /**
         * API route to start a notebook  Returns:     :Response: API response
         * @summary Start Notebook
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startNotebook(notebook_id, options) {
            return NotebooksApiFp(configuration).startNotebook(notebook_id, options)(fetch, basePath);
        },
        /**
         * API route to stop notebooks  Returns:     :Response: API response
         * @summary Stop Notebook
         * @param {string} notebook_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopNotebook(notebook_id, options) {
            return NotebooksApiFp(configuration).stopNotebook(notebook_id, options)(fetch, basePath);
        },
    };
};
/**
 * NotebooksApi - object-oriented interface
 * @export
 * @class NotebooksApi
 * @extends {BaseAPI}
 */
export class NotebooksApi extends BaseAPI {
    /**
     * API route to create a notebook  Returns:     :Response: API response
     * @summary Create Notebook
     * @param {CreateNotebookData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    createNotebook(body, options) {
        return NotebooksApiFp(this.configuration).createNotebook(body, options)(this.fetch, this.basePath);
    }
    /**
     * API route to delete a notebook  Returns:     :Response: API response
     * @summary Delete Notebook
     * @param {string} notebook_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    deleteNotebook(notebook_id, options) {
        return NotebooksApiFp(this.configuration).deleteNotebook(notebook_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to get notebooks  Returns:     :Response: API response
     * @summary Get Notebooks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    getNotebooks(options) {
        return NotebooksApiFp(this.configuration).getNotebooks(options)(this.fetch, this.basePath);
    }
    /**
     * API route to get a presigned URL for the notebook  Returns:     :Response: API response
     * @summary Get Presigned Url
     * @param {string} notebook_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    getPresignedUrl(notebook_id, options) {
        return NotebooksApiFp(this.configuration).getPresignedUrl(notebook_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to start a notebook  Returns:     :Response: API response
     * @summary Start Notebook
     * @param {string} notebook_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    startNotebook(notebook_id, options) {
        return NotebooksApiFp(this.configuration).startNotebook(notebook_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to stop notebooks  Returns:     :Response: API response
     * @summary Stop Notebook
     * @param {string} notebook_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotebooksApi
     */
    stopNotebook(notebook_id, options) {
        return NotebooksApiFp(this.configuration).stopNotebook(notebook_id, options)(this.fetch, this.basePath);
    }
}
/**
 * ScenariosApi - fetch parameter creator
 * @export
 */
export const ScenariosApiFetchParamCreator = function (configuration) {
    return {
        /**
         * Associates a user's Scenario with one of their UserAssessments.  The requesting user must own both the Scenario and UserAssessment. Additionally, the UserAssessment must not already be associated with a Scenario and the Scenario must not already be associated with a a UserAssessment.  Args:     scenario_id (str): The ID for one of the user's Scenarios.     user_assessment_id (str): The ID for one of the user's UserAssessments.     session (Session): A boto3 session.     user (User): The owner of the Scenario and UserAssessment.  Returns:     EmptyResponse: No response data is returned.
         * @summary Associate Scenario With User Assessment
         * @param {string} scenario_id
         * @param {string} user_assessment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        associateScenarioWithUserAssessment(scenario_id, user_assessment_id, options = {}) {
            // verify required parameter 'scenario_id' is not null or undefined
            if (scenario_id === null || scenario_id === undefined) {
                throw new RequiredError('scenario_id', 'Required parameter scenario_id was null or undefined when calling associateScenarioWithUserAssessment.');
            }
            // verify required parameter 'user_assessment_id' is not null or undefined
            if (user_assessment_id === null ||
                user_assessment_id === undefined) {
                throw new RequiredError('user_assessment_id', 'Required parameter user_assessment_id was null or undefined when calling associateScenarioWithUserAssessment.');
            }
            const localVarPath = `/scenarios/{scenario_id}/associate/{user_assessment_id}`
                .replace(`{${'scenario_id'}}`, encodeURIComponent(String(scenario_id)))
                .replace(`{${'user_assessment_id'}}`, encodeURIComponent(String(user_assessment_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create Scenario
         * @param {CreateScenarioData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenario(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createScenario.');
            }
            const localVarPath = `/scenarios`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = 'CreateScenarioData' !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] ===
                    'application/json';
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || '';
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete Scenario
         * @param {string} scenario_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenario(scenario_id, options = {}) {
            // verify required parameter 'scenario_id' is not null or undefined
            if (scenario_id === null || scenario_id === undefined) {
                throw new RequiredError('scenario_id', 'Required parameter scenario_id was null or undefined when calling deleteScenario.');
            }
            const localVarPath = `/scenarios/{scenario_id}`.replace(`{${'scenario_id'}}`, encodeURIComponent(String(scenario_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Scenario
         * @param {string} scenario_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenario(scenario_id, options = {}) {
            // verify required parameter 'scenario_id' is not null or undefined
            if (scenario_id === null || scenario_id === undefined) {
                throw new RequiredError('scenario_id', 'Required parameter scenario_id was null or undefined when calling getScenario.');
            }
            const localVarPath = `/scenarios/{scenario_id}`.replace(`{${'scenario_id'}}`, encodeURIComponent(String(scenario_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all the Scenarios for a particular user.  The list of Scenarios is sorted in descending order based on the date that the Scenarios was created.  Args:     session (Session): A boto3 session.     user (User): The owner of the Scenarios.  Returns:     List[Scenario]: A list of Scenarios.
         * @summary List Scenarios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScenarios(options = {}) {
            const localVarPath = `/scenarios/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ScenariosApi - functional programming interface
 * @export
 */
export const ScenariosApiFp = function (configuration) {
    return {
        /**
         * Associates a user's Scenario with one of their UserAssessments.  The requesting user must own both the Scenario and UserAssessment. Additionally, the UserAssessment must not already be associated with a Scenario and the Scenario must not already be associated with a a UserAssessment.  Args:     scenario_id (str): The ID for one of the user's Scenarios.     user_assessment_id (str): The ID for one of the user's UserAssessments.     session (Session): A boto3 session.     user (User): The owner of the Scenario and UserAssessment.  Returns:     EmptyResponse: No response data is returned.
         * @summary Associate Scenario With User Assessment
         * @param {string} scenario_id
         * @param {string} user_assessment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        associateScenarioWithUserAssessment(scenario_id, user_assessment_id, options) {
            const localVarFetchArgs = ScenariosApiFetchParamCreator(configuration).associateScenarioWithUserAssessment(scenario_id, user_assessment_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Create Scenario
         * @param {CreateScenarioData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenario(body, options) {
            const localVarFetchArgs = ScenariosApiFetchParamCreator(configuration).createScenario(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Delete Scenario
         * @param {string} scenario_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenario(scenario_id, options) {
            const localVarFetchArgs = ScenariosApiFetchParamCreator(configuration).deleteScenario(scenario_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get Scenario
         * @param {string} scenario_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenario(scenario_id, options) {
            const localVarFetchArgs = ScenariosApiFetchParamCreator(configuration).getScenario(scenario_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Retrieve all the Scenarios for a particular user.  The list of Scenarios is sorted in descending order based on the date that the Scenarios was created.  Args:     session (Session): A boto3 session.     user (User): The owner of the Scenarios.  Returns:     List[Scenario]: A list of Scenarios.
         * @summary List Scenarios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScenarios(options) {
            const localVarFetchArgs = ScenariosApiFetchParamCreator(configuration).listScenarios(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * ScenariosApi - factory interface
 * @export
 */
export const ScenariosApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * Associates a user's Scenario with one of their UserAssessments.  The requesting user must own both the Scenario and UserAssessment. Additionally, the UserAssessment must not already be associated with a Scenario and the Scenario must not already be associated with a a UserAssessment.  Args:     scenario_id (str): The ID for one of the user's Scenarios.     user_assessment_id (str): The ID for one of the user's UserAssessments.     session (Session): A boto3 session.     user (User): The owner of the Scenario and UserAssessment.  Returns:     EmptyResponse: No response data is returned.
         * @summary Associate Scenario With User Assessment
         * @param {string} scenario_id
         * @param {string} user_assessment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        associateScenarioWithUserAssessment(scenario_id, user_assessment_id, options) {
            return ScenariosApiFp(configuration).associateScenarioWithUserAssessment(scenario_id, user_assessment_id, options)(fetch, basePath);
        },
        /**
         *
         * @summary Create Scenario
         * @param {CreateScenarioData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenario(body, options) {
            return ScenariosApiFp(configuration).createScenario(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary Delete Scenario
         * @param {string} scenario_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenario(scenario_id, options) {
            return ScenariosApiFp(configuration).deleteScenario(scenario_id, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get Scenario
         * @param {string} scenario_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenario(scenario_id, options) {
            return ScenariosApiFp(configuration).getScenario(scenario_id, options)(fetch, basePath);
        },
        /**
         * Retrieve all the Scenarios for a particular user.  The list of Scenarios is sorted in descending order based on the date that the Scenarios was created.  Args:     session (Session): A boto3 session.     user (User): The owner of the Scenarios.  Returns:     List[Scenario]: A list of Scenarios.
         * @summary List Scenarios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScenarios(options) {
            return ScenariosApiFp(configuration).listScenarios(options)(fetch, basePath);
        },
    };
};
/**
 * ScenariosApi - object-oriented interface
 * @export
 * @class ScenariosApi
 * @extends {BaseAPI}
 */
export class ScenariosApi extends BaseAPI {
    /**
     * Associates a user's Scenario with one of their UserAssessments.  The requesting user must own both the Scenario and UserAssessment. Additionally, the UserAssessment must not already be associated with a Scenario and the Scenario must not already be associated with a a UserAssessment.  Args:     scenario_id (str): The ID for one of the user's Scenarios.     user_assessment_id (str): The ID for one of the user's UserAssessments.     session (Session): A boto3 session.     user (User): The owner of the Scenario and UserAssessment.  Returns:     EmptyResponse: No response data is returned.
     * @summary Associate Scenario With User Assessment
     * @param {string} scenario_id
     * @param {string} user_assessment_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    associateScenarioWithUserAssessment(scenario_id, user_assessment_id, options) {
        return ScenariosApiFp(this.configuration).associateScenarioWithUserAssessment(scenario_id, user_assessment_id, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Create Scenario
     * @param {CreateScenarioData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    createScenario(body, options) {
        return ScenariosApiFp(this.configuration).createScenario(body, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Delete Scenario
     * @param {string} scenario_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    deleteScenario(scenario_id, options) {
        return ScenariosApiFp(this.configuration).deleteScenario(scenario_id, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Get Scenario
     * @param {string} scenario_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    getScenario(scenario_id, options) {
        return ScenariosApiFp(this.configuration).getScenario(scenario_id, options)(this.fetch, this.basePath);
    }
    /**
     * Retrieve all the Scenarios for a particular user.  The list of Scenarios is sorted in descending order based on the date that the Scenarios was created.  Args:     session (Session): A boto3 session.     user (User): The owner of the Scenarios.  Returns:     List[Scenario]: A list of Scenarios.
     * @summary List Scenarios
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    listScenarios(options) {
        return ScenariosApiFp(this.configuration).listScenarios(options)(this.fetch, this.basePath);
    }
}
/**
 * TasksApi - fetch parameter creator
 * @export
 */
export const TasksApiFetchParamCreator = function (configuration) {
    return {
        /**
         * API route to fetch an action output  Returns:     :dict: Action response
         * @summary Get Action Output
         * @param {string} context
         * @param {string} task_id
         * @param {string} job_id
         * @param {number} action_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionOutput(context, task_id, job_id, action_id, options = {}) {
            // verify required parameter 'context' is not null or undefined
            if (context === null || context === undefined) {
                throw new RequiredError('context', 'Required parameter context was null or undefined when calling getActionOutput.');
            }
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id', 'Required parameter task_id was null or undefined when calling getActionOutput.');
            }
            // verify required parameter 'job_id' is not null or undefined
            if (job_id === null || job_id === undefined) {
                throw new RequiredError('job_id', 'Required parameter job_id was null or undefined when calling getActionOutput.');
            }
            // verify required parameter 'action_id' is not null or undefined
            if (action_id === null || action_id === undefined) {
                throw new RequiredError('action_id', 'Required parameter action_id was null or undefined when calling getActionOutput.');
            }
            const localVarPath = `/tasks/{context}/{task_id}/{job_id}/{action_id}/output`
                .replace(`{${'context'}}`, encodeURIComponent(String(context)))
                .replace(`{${'task_id'}}`, encodeURIComponent(String(task_id)))
                .replace(`{${'job_id'}}`, encodeURIComponent(String(job_id)))
                .replace(`{${'action_id'}}`, encodeURIComponent(String(action_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to fetch a specific task  Returns:     :Response: API response
         * @summary Get Task
         * @param {string} context
         * @param {string} task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(context, task_id, options = {}) {
            // verify required parameter 'context' is not null or undefined
            if (context === null || context === undefined) {
                throw new RequiredError('context', 'Required parameter context was null or undefined when calling getTask.');
            }
            // verify required parameter 'task_id' is not null or undefined
            if (task_id === null || task_id === undefined) {
                throw new RequiredError('task_id', 'Required parameter task_id was null or undefined when calling getTask.');
            }
            const localVarPath = `/tasks/{context}/{task_id}`
                .replace(`{${'context'}}`, encodeURIComponent(String(context)))
                .replace(`{${'task_id'}}`, encodeURIComponent(String(task_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to get tasks  Returns:     :Response: API response
         * @summary Get Tasks
         * @param {string} context
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(context, options = {}) {
            // verify required parameter 'context' is not null or undefined
            if (context === null || context === undefined) {
                throw new RequiredError('context', 'Required parameter context was null or undefined when calling getTasks.');
            }
            const localVarPath = `/tasks/{context}`.replace(`{${'context'}}`, encodeURIComponent(String(context)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to run a specific task  Returns:     :Response: API response
         * @summary Run Task
         * @param {RunTaskData} body
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTask(body, environment_id, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling runTask.');
            }
            // verify required parameter 'environment_id' is not null or undefined
            if (environment_id === null || environment_id === undefined) {
                throw new RequiredError('environment_id', 'Required parameter environment_id was null or undefined when calling runTask.');
            }
            const localVarPath = `/tasks/{environment_id}`.replace(`{${'environment_id'}}`, encodeURIComponent(String(environment_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = 'RunTaskData' !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] ===
                    'application/json';
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || '';
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function (configuration) {
    return {
        /**
         * API route to fetch an action output  Returns:     :dict: Action response
         * @summary Get Action Output
         * @param {string} context
         * @param {string} task_id
         * @param {string} job_id
         * @param {number} action_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionOutput(context, task_id, job_id, action_id, options) {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).getActionOutput(context, task_id, job_id, action_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to fetch a specific task  Returns:     :Response: API response
         * @summary Get Task
         * @param {string} context
         * @param {string} task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(context, task_id, options) {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).getTask(context, task_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to get tasks  Returns:     :Response: API response
         * @summary Get Tasks
         * @param {string} context
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(context, options) {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).getTasks(context, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to run a specific task  Returns:     :Response: API response
         * @summary Run Task
         * @param {RunTaskData} body
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTask(body, environment_id, options) {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).runTask(body, environment_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * API route to fetch an action output  Returns:     :dict: Action response
         * @summary Get Action Output
         * @param {string} context
         * @param {string} task_id
         * @param {string} job_id
         * @param {number} action_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionOutput(context, task_id, job_id, action_id, options) {
            return TasksApiFp(configuration).getActionOutput(context, task_id, job_id, action_id, options)(fetch, basePath);
        },
        /**
         * API route to fetch a specific task  Returns:     :Response: API response
         * @summary Get Task
         * @param {string} context
         * @param {string} task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(context, task_id, options) {
            return TasksApiFp(configuration).getTask(context, task_id, options)(fetch, basePath);
        },
        /**
         * API route to get tasks  Returns:     :Response: API response
         * @summary Get Tasks
         * @param {string} context
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(context, options) {
            return TasksApiFp(configuration).getTasks(context, options)(fetch, basePath);
        },
        /**
         * API route to run a specific task  Returns:     :Response: API response
         * @summary Run Task
         * @param {RunTaskData} body
         * @param {string} environment_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTask(body, environment_id, options) {
            return TasksApiFp(configuration).runTask(body, environment_id, options)(fetch, basePath);
        },
    };
};
/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * API route to fetch an action output  Returns:     :dict: Action response
     * @summary Get Action Output
     * @param {string} context
     * @param {string} task_id
     * @param {string} job_id
     * @param {number} action_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    getActionOutput(context, task_id, job_id, action_id, options) {
        return TasksApiFp(this.configuration).getActionOutput(context, task_id, job_id, action_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to fetch a specific task  Returns:     :Response: API response
     * @summary Get Task
     * @param {string} context
     * @param {string} task_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    getTask(context, task_id, options) {
        return TasksApiFp(this.configuration).getTask(context, task_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to get tasks  Returns:     :Response: API response
     * @summary Get Tasks
     * @param {string} context
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    getTasks(context, options) {
        return TasksApiFp(this.configuration).getTasks(context, options)(this.fetch, this.basePath);
    }
    /**
     * API route to run a specific task  Returns:     :Response: API response
     * @summary Run Task
     * @param {RunTaskData} body
     * @param {string} environment_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    runTask(body, environment_id, options) {
        return TasksApiFp(this.configuration).runTask(body, environment_id, options)(this.fetch, this.basePath);
    }
}
/**
 * TemplatesApi - fetch parameter creator
 * @export
 */
export const TemplatesApiFetchParamCreator = function (configuration) {
    return {
        /**
         * API route to create a template  Returns:     :Response: API response
         * @summary Create Template
         * @param {CreateTemplateData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createTemplate.');
            }
            const localVarPath = `/templates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = 'CreateTemplateData' !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] ===
                    'application/json';
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || '';
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to delete a template  Returns:     :Response: API response
         * @summary Delete Template
         * @param {string} template_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(template_id, options = {}) {
            // verify required parameter 'template_id' is not null or undefined
            if (template_id === null || template_id === undefined) {
                throw new RequiredError('template_id', 'Required parameter template_id was null or undefined when calling deleteTemplate.');
            }
            const localVarPath = `/templates/{template_id}`.replace(`{${'template_id'}}`, encodeURIComponent(String(template_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to fetch templates  Returns:     :Response: API response
         * @summary Get Template
         * @param {string} template_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(template_id, options = {}) {
            // verify required parameter 'template_id' is not null or undefined
            if (template_id === null || template_id === undefined) {
                throw new RequiredError('template_id', 'Required parameter template_id was null or undefined when calling getTemplate.');
            }
            const localVarPath = `/templates/{template_id}`.replace(`{${'template_id'}}`, encodeURIComponent(String(template_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to list templates  Returns:     :Response: API response
         * @summary List Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(options = {}) {
            const localVarPath = `/templates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API route to update a template  Returns:     :Response: API response
         * @summary Update Template
         * @param {UpdateTemplateData} body
         * @param {string} template_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(body, template_id, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateTemplate.');
            }
            // verify required parameter 'template_id' is not null or undefined
            if (template_id === null || template_id === undefined) {
                throw new RequiredError('template_id', 'Required parameter template_id was null or undefined when calling updateTemplate.');
            }
            const localVarPath = `/templates/{template_id}`.replace(`{${'template_id'}}`, encodeURIComponent(String(template_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = 'UpdateTemplateData' !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] ===
                    'application/json';
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || '';
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TemplatesApi - functional programming interface
 * @export
 */
export const TemplatesApiFp = function (configuration) {
    return {
        /**
         * API route to create a template  Returns:     :Response: API response
         * @summary Create Template
         * @param {CreateTemplateData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(body, options) {
            const localVarFetchArgs = TemplatesApiFetchParamCreator(configuration).createTemplate(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to delete a template  Returns:     :Response: API response
         * @summary Delete Template
         * @param {string} template_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(template_id, options) {
            const localVarFetchArgs = TemplatesApiFetchParamCreator(configuration).deleteTemplate(template_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to fetch templates  Returns:     :Response: API response
         * @summary Get Template
         * @param {string} template_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(template_id, options) {
            const localVarFetchArgs = TemplatesApiFetchParamCreator(configuration).getTemplate(template_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to list templates  Returns:     :Response: API response
         * @summary List Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(options) {
            const localVarFetchArgs = TemplatesApiFetchParamCreator(configuration).listTemplates(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         * API route to update a template  Returns:     :Response: API response
         * @summary Update Template
         * @param {UpdateTemplateData} body
         * @param {string} template_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(body, template_id, options) {
            const localVarFetchArgs = TemplatesApiFetchParamCreator(configuration).updateTemplate(body, template_id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * TemplatesApi - factory interface
 * @export
 */
export const TemplatesApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         * API route to create a template  Returns:     :Response: API response
         * @summary Create Template
         * @param {CreateTemplateData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(body, options) {
            return TemplatesApiFp(configuration).createTemplate(body, options)(fetch, basePath);
        },
        /**
         * API route to delete a template  Returns:     :Response: API response
         * @summary Delete Template
         * @param {string} template_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(template_id, options) {
            return TemplatesApiFp(configuration).deleteTemplate(template_id, options)(fetch, basePath);
        },
        /**
         * API route to fetch templates  Returns:     :Response: API response
         * @summary Get Template
         * @param {string} template_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(template_id, options) {
            return TemplatesApiFp(configuration).getTemplate(template_id, options)(fetch, basePath);
        },
        /**
         * API route to list templates  Returns:     :Response: API response
         * @summary List Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(options) {
            return TemplatesApiFp(configuration).listTemplates(options)(fetch, basePath);
        },
        /**
         * API route to update a template  Returns:     :Response: API response
         * @summary Update Template
         * @param {UpdateTemplateData} body
         * @param {string} template_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(body, template_id, options) {
            return TemplatesApiFp(configuration).updateTemplate(body, template_id, options)(fetch, basePath);
        },
    };
};
/**
 * TemplatesApi - object-oriented interface
 * @export
 * @class TemplatesApi
 * @extends {BaseAPI}
 */
export class TemplatesApi extends BaseAPI {
    /**
     * API route to create a template  Returns:     :Response: API response
     * @summary Create Template
     * @param {CreateTemplateData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    createTemplate(body, options) {
        return TemplatesApiFp(this.configuration).createTemplate(body, options)(this.fetch, this.basePath);
    }
    /**
     * API route to delete a template  Returns:     :Response: API response
     * @summary Delete Template
     * @param {string} template_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    deleteTemplate(template_id, options) {
        return TemplatesApiFp(this.configuration).deleteTemplate(template_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to fetch templates  Returns:     :Response: API response
     * @summary Get Template
     * @param {string} template_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    getTemplate(template_id, options) {
        return TemplatesApiFp(this.configuration).getTemplate(template_id, options)(this.fetch, this.basePath);
    }
    /**
     * API route to list templates  Returns:     :Response: API response
     * @summary List Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    listTemplates(options) {
        return TemplatesApiFp(this.configuration).listTemplates(options)(this.fetch, this.basePath);
    }
    /**
     * API route to update a template  Returns:     :Response: API response
     * @summary Update Template
     * @param {UpdateTemplateData} body
     * @param {string} template_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    updateTemplate(body, template_id, options) {
        return TemplatesApiFp(this.configuration).updateTemplate(body, template_id, options)(this.fetch, this.basePath);
    }
}
/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create User
         * @param {CreateUserData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createUser.');
            }
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = 'CreateUserData' !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] ===
                    'application/json';
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || '';
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete User
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(username, options = {}) {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username', 'Required parameter username was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/users/{username}`.replace(`{${'username'}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Cognito User
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCognitoUser(username, options = {}) {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username', 'Required parameter username was null or undefined when calling getCognitoUser.');
            }
            const localVarPath = `/users/{username}`.replace(`{${'username'}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(options = {}) {
            const localVarPath = `/users/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update User
         * @param {UpdateUserData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateUser.');
            }
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = 'UpdateUserData' !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] ===
                    'application/json';
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || '';
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create User
         * @param {CreateUserData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(body, options) {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).createUser(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Delete User
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(username, options) {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).deleteUser(username, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get Cognito User
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCognitoUser(username, options) {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getCognitoUser(username, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary List Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(options) {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).listUsers(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Update User
         * @param {UpdateUserData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(body, options) {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).updateUser(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Create User
         * @param {CreateUserData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(body, options) {
            return UsersApiFp(configuration).createUser(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary Delete User
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(username, options) {
            return UsersApiFp(configuration).deleteUser(username, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get Cognito User
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCognitoUser(username, options) {
            return UsersApiFp(configuration).getCognitoUser(username, options)(fetch, basePath);
        },
        /**
         *
         * @summary List Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(options) {
            return UsersApiFp(configuration).listUsers(options)(fetch, basePath);
        },
        /**
         *
         * @summary Update User
         * @param {UpdateUserData} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(body, options) {
            return UsersApiFp(configuration).updateUser(body, options)(fetch, basePath);
        },
    };
};
/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     *
     * @summary Create User
     * @param {CreateUserData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    createUser(body, options) {
        return UsersApiFp(this.configuration).createUser(body, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Delete User
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    deleteUser(username, options) {
        return UsersApiFp(this.configuration).deleteUser(username, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Get Cognito User
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    getCognitoUser(username, options) {
        return UsersApiFp(this.configuration).getCognitoUser(username, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary List Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    listUsers(options) {
        return UsersApiFp(this.configuration).listUsers(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Update User
     * @param {UpdateUserData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    updateUser(body, options) {
        return UsersApiFp(this.configuration).updateUser(body, options)(this.fetch, this.basePath);
    }
}
