import { jsx as _jsx } from "react/jsx-runtime";
import { DataTable } from '../../widgets/table';
export default function UserTable({ users, rowsPerPage, handleSelected, }) {
    const data = users.map(({ username, email, role }) => ({
        username: username,
        email: email,
        role: role,
    }));
    function handleOnSelected(record) {
        handleSelected((record === null || record === void 0 ? void 0 : record.email) || '');
    }
    const columns = [
        {
            label: 'Name',
            name: 'username',
            valueType: 'string',
            format: (name) => name,
        },
        {
            label: 'Email',
            name: 'email',
            valueType: 'string',
            format: (email) => email,
        },
    ];
    return (_jsx(DataTable, { color: "blue", data: data, rowsPerPage: rowsPerPage, onSelected: handleOnSelected, columns: columns }));
}
