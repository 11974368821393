// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n\t--row-count: 6;\n}\n\n.scfLniNVopiajFA4NJjA {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-between;\n\theight: 100%;\n\tmin-height: calc((var(--row-count) * 42px) + 50px);\n}\n\n.F1TCdp7uAK22qsfMWc09 {\n\tborder-left: none !important;\n\tborder-right: none !important;\n\tborder-bottom: none !important;\n\tmargin-bottom: 0 !important;\n}\n\n.LPC9Q6ImT3UD5DkUqd99 {\n\tcursor: pointer !important;\n\tuser-select: none !important;\n}\n\n.LPC9Q6ImT3UD5DkUqd99:hover {\n\tfilter: brightness(95%);\n}\n\n.nZjt5Ys5qlvY3qF9qF5R {\n\theight: calc((var(--row-count) * 42px) + 50px);\n\ttransition: height 2s ease-in-out;\n}\n\n.JWnYKw57r5c5nYZrr27A {\n\theight: calc((var(--row-count) * 42px) + 50px);\n}\n", "",{"version":3,"sources":["webpack://./src/components/widgets/table.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;AACf;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,8BAA8B;CAC9B,YAAY;CACZ,kDAAkD;AACnD;;AAEA;CACC,4BAA4B;CAC5B,6BAA6B;CAC7B,8BAA8B;CAC9B,2BAA2B;AAC5B;;AAEA;CACC,0BAA0B;CAC1B,4BAA4B;AAC7B;;AAEA;CACC,uBAAuB;AACxB;;AAEA;CACC,8CAA8C;CAC9C,iCAAiC;AAClC;;AAEA;CACC,8CAA8C;AAC/C","sourcesContent":[":root {\n\t--row-count: 6;\n}\n\n.tableContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-between;\n\theight: 100%;\n\tmin-height: calc((var(--row-count) * 42px) + 50px);\n}\n\n.table {\n\tborder-left: none !important;\n\tborder-right: none !important;\n\tborder-bottom: none !important;\n\tmargin-bottom: 0 !important;\n}\n\n.tableHeader {\n\tcursor: pointer !important;\n\tuser-select: none !important;\n}\n\n.tableHeader:hover {\n\tfilter: brightness(95%);\n}\n\n.tableContent {\n\theight: calc((var(--row-count) * 42px) + 50px);\n\ttransition: height 2s ease-in-out;\n}\n\n.loadingContent {\n\theight: calc((var(--row-count) * 42px) + 50px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "scfLniNVopiajFA4NJjA",
	"table": "F1TCdp7uAK22qsfMWc09",
	"tableHeader": "LPC9Q6ImT3UD5DkUqd99",
	"tableContent": "nZjt5Ys5qlvY3qF9qF5R",
	"loadingContent": "JWnYKw57r5c5nYZrr27A"
};
export default ___CSS_LOADER_EXPORT___;
