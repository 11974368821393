// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M2audCmVkOhk1pDa9LsF {\n\theight: calc((var(--row-count) * 42px) + 50px);\n}\n\n.inKrCyf2lkzdaakXZN4f {\n\theight: calc((var(--row-count) * 42px) + 50px);\n\ttransition: height 2s ease-in-out;\n}\n", "",{"version":3,"sources":["webpack://./src/components/users/members.module.css"],"names":[],"mappings":"AAAA;CACC,8CAA8C;AAC/C;;AAEA;CACC,8CAA8C;CAC9C,iCAAiC;AAClC","sourcesContent":[".loadingContent {\n\theight: calc((var(--row-count) * 42px) + 50px);\n}\n\n.tableContent {\n\theight: calc((var(--row-count) * 42px) + 50px);\n\ttransition: height 2s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContent": "M2audCmVkOhk1pDa9LsF",
	"tableContent": "inKrCyf2lkzdaakXZN4f"
};
export default ___CSS_LOADER_EXPORT___;
